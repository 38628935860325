import React from 'react';
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {useStyles} from "../../../jss/DashboardStyles";
import {DroppableCell} from "./DroppableCell";
import {InputCell} from "./InputCell";
import {
    FACTOR_DROPPABLE_ID,
    GROUP_DROPPABLE_ID,
    INDICATOR_DROPPABLE_ID,
    STAGE_DROPPABLE_ID
} from "../../../const/UIConst";
import {EmptyCell, SimpleCell} from "./SimpleCell";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

export const DesktopTable = () => {

    const classes = useStyles()
    const { t } = useTranslation();
    const stages = useSelector (store => store.desktop.factorModel.stages)
    const groups = useSelector (store => store.desktop.factorModel.groups)

    const getGroupRowSpan = (id) => {
        let rowSpan = 1
        const group = groups.find(group => group.groupId === id)
        if (group.factors) {
            rowSpan += group.factors.length
            for (let factor of group.factors) {
                if (factor.indicators)
                    rowSpan += factor.indicators.length
            }
        }
        return rowSpan
    }

    const getFactorRowSpan = (id) => {
        let rowSpan = 1
        let factor
        for(let group of groups ){
            factor = group.factors.find(factor => factor.factorId===id)
            if (factor)
                break
        }
        if (factor.indicators)
            rowSpan += factor.indicators.length
        return rowSpan
    }

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <DroppableCell dropType={GROUP_DROPPABLE_ID} header caption={t("groupsLabel")}/>
                            <TableCell className={classes.tableHeader} align="left">{t("factorsLabel")}</TableCell>
                            <TableCell className={classes.tableHeader} align="left">{t("indicatorsLabel")}</TableCell>

                            {stages && stages.length>0 && stages.map((stage,index)=>{
                                return(
                                    <DroppableCell key={index} item={stage} dropType={STAGE_DROPPABLE_ID} header/>
                                )
                            })}
                            {
                                !(stages && stages.length>0) &&  <DroppableCell dropType={STAGE_DROPPABLE_ID} header caption={t("stagesLabel")}/>
                            }

                        </TableRow>
                    </TableHead>
                    <TableBody>

                        { groups && groups.length>0 &&
                            groups.map((group,index) => {
                                return(
                                    <React.Fragment key={"GROUP_"+index.toString()}>
                                        <TableRow>
                                            <DroppableCell rowSpan={getGroupRowSpan(group.groupId)} item={group} dropType={FACTOR_DROPPABLE_ID} align="left"/>
                                            <EmptyCell/>
                                            <EmptyCell/>
                                            {
                                                group.stageWeights.map((weight,index)=>{
                                                    return(
                                                        <InputCell key={"GROUP_WEIGHT_"+index.toString()} item={weight} coordinates={{ groupId: group.groupId, factorId: null, indicatorId: null, stageId: weight.stageId }} />
                                                    )
                                                })
                                            }
                                        </TableRow>

                                        {group.factors && group.factors.length>0 &&
                                            group.factors.map((factor,index)=>{
                                                return(
                                                    <React.Fragment key={"FACTOR_"+index.toString()}>
                                                        <TableRow>
                                                            <DroppableCell rowSpan={getFactorRowSpan(factor.factorId)} item={factor} dropType={INDICATOR_DROPPABLE_ID} align="left" parentProps={{groupId: group.groupId}}/>
                                                            <EmptyCell/>
                                                            {
                                                                factor.stageWeights.map((weight,index)=>{
                                                                    return(
                                                                        <InputCell key={"FACTOR_WEIGHT_"+index.toString()} item={weight} coordinates={{ groupId: group.groupId, factorId: factor.factorId, indicatorId: null, stageId: weight.stageId }} />
                                                                    )
                                                                })
                                                            }
                                                        </TableRow>
                                                        {factor.indicators && factor.indicators.length>0 &&
                                                            factor.indicators.map((indicator,index)=>{
                                                                return(
                                                                    <TableRow key={"INDICATOR_"+index.toString()}>
                                                                        <SimpleCell item={indicator} align="left" parentProps={{groupId: group.groupId, factorId: factor.factorId}}/>
                                                                        {
                                                                            indicator.stageWeights.map((weight,index)=>{
                                                                                return(
                                                                                    <InputCell key={"INDICATOR_WEIGHT_"+index.toString()} item={weight} indicator coordinates={{ groupId: group.groupId, factorId: factor.factorId, indicatorId: indicator.indicatorId, stageId: weight.stageId }}/>
                                                                                )
                                                                            })
                                                                        }
                                                                    </TableRow>
                                                                )
                                                            })
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </React.Fragment>
                                )
                            })
                        }
                        { !(groups && groups.length>0) &&
                            <TableRow>
                                <DroppableCell align="left" dropType={FACTOR_DROPPABLE_ID}/>
                            </TableRow>
                        }

                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}