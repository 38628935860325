import React, {useEffect} from 'react';
import {EmptyDesktop} from "./EmptyDesktop";
import {Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {DesktopTable} from "./DesktopTable";
import {MODAL_CHECK_INDICATORS_CLOSE, MODAL_CHECK_MODEL_CLOSE} from "../../../ducks/SidebarSettingsReducer";

export const Desktop = () => {

    const factorModel = useSelector(store => store.desktop.factorModel)
    const isEmptyModel = factorModel.stages.length<1 && factorModel.groups.length<1
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({type:MODAL_CHECK_INDICATORS_CLOSE})
        dispatch({type:MODAL_CHECK_MODEL_CLOSE})
    })

    return(
        <Grid container
              style={{}}
        >
            {!isEmptyModel && <DesktopTable/>}
            {isEmptyModel && <EmptyDesktop/>}
        </Grid>

    )
}