import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {
    CLOSE_SIDEBAR_LEFT,
    OPEN_SIDEBAR_LEFT,
} from "../../ducks/UserInterfaceReducer";
import {useStyles} from "../../jss/DashboardStyles";
import Divider from "@material-ui/core/Divider";
import {PALETTE_SIDEBAR_WIDTH, TOP_PANEL_HEIGHT} from "../../const/UIConst";
import {Grid, Typography} from "@material-ui/core";
import {PaletteList} from "./PaletteList";
import {FACTOR_REQUEST, GROUP_REQUEST, INDICATOR_REQUEST, STAGE_REQUEST} from "../../ducks/PaletteReducer";
import Alert from "@material-ui/lab/Alert";
import {useTranslation} from "react-i18next";

export const SidebarPalette = (props) => {
    useStyles();
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const sidebarOpened = useSelector(store => store.user_interface.sidebarPaletteOpen)
    const error = useSelector(state => state.palette.error)

    function toggleSidebar() {
        if (sidebarOpened === true) {
            dispatch({ type: CLOSE_SIDEBAR_LEFT });
        } else {
            dispatch({ type: OPEN_SIDEBAR_LEFT });
        }
    }

    return (
        <>
            <Drawer
                variant="persistent"
                open={sidebarOpened}
                anchor={"left"}
            >
                <Grid container style={{width:PALETTE_SIDEBAR_WIDTH, maxWidth:PALETTE_SIDEBAR_WIDTH, position:"relative",minHeight:TOP_PANEL_HEIGHT}} alignItems={"center"} justify={"center"}>
                    <div style={{width:PALETTE_SIDEBAR_WIDTH, maxWidth:PALETTE_SIDEBAR_WIDTH, position:"absolute"}}>
                        <IconButton onClick={toggleSidebar} style={{float:"right"}}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </div>
                    <Grid item>
                        <Typography >
                            {t("palette")}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider/>

                {error && <Alert severity='error' > {error} </Alert>}

                <PaletteList request_action={STAGE_REQUEST}/>
                <PaletteList request_action={GROUP_REQUEST}/>
                <PaletteList request_action={FACTOR_REQUEST}/>
                <PaletteList request_action={INDICATOR_REQUEST}/>

            </Drawer>
        </>
    )
}
