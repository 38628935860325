import { takeLatest, put, call} from 'redux-saga/effects'
import {
    FACTOR_REQUEST, FACTOR_REQUEST_FAILED, FACTOR_REQUEST_SUCCESS,
    GROUP_REQUEST, GROUP_REQUEST_FAILED, GROUP_REQUEST_SUCCESS,
    INDICATOR_REQUEST, INDICATOR_REQUEST_FAILED, INDICATOR_REQUEST_SUCCESS,
    STAGE_REQUEST, STAGE_REQUEST_FAILED,
    STAGE_REQUEST_SUCCESS
} from "../ducks/PaletteReducer";
import {
    getErrorMessage,
    simpleRequest,
    URL_GET_FACTOR_LIST,
    URL_GET_GROUP_LIST,
    URL_GET_INDICATOR_LIST,
    URL_GET_STAGE_LIST
} from "../service/RequestService";

export function* PaletteSagaWatcher() {
    yield takeLatest(STAGE_REQUEST, getRequest)
    yield takeLatest(GROUP_REQUEST, getRequest)
    yield takeLatest(FACTOR_REQUEST, getRequest)
    yield takeLatest(INDICATOR_REQUEST, getRequest)
}

function* getRequest(action){
    try {
        const  response  = yield call(simpleRequest,getRequestUrlByAction(action) ,'GET',null,null);
        yield put({ type: getSuccessActionByAction(action), payload: response.data });
    } catch (error) {
        yield put({ type: getErrorActionByAction(action), payload: getErrorMessage(error.response.status) });
    }
}

function getRequestUrlByAction(action){
    switch (action.type){
        case STAGE_REQUEST: {
            return URL_GET_STAGE_LIST
        }
        case GROUP_REQUEST: {
            return URL_GET_GROUP_LIST
        }
        case FACTOR_REQUEST: {
            return URL_GET_FACTOR_LIST
        }
        case INDICATOR_REQUEST: {
            return URL_GET_INDICATOR_LIST
        }
        default:
            return ""
    }
}

function getSuccessActionByAction(action){
    switch (action.type){
        case STAGE_REQUEST: {
            return STAGE_REQUEST_SUCCESS
        }
        case GROUP_REQUEST: {
            return GROUP_REQUEST_SUCCESS
        }
        case FACTOR_REQUEST: {
            return FACTOR_REQUEST_SUCCESS
        }
        case INDICATOR_REQUEST: {
            return INDICATOR_REQUEST_SUCCESS
        }
        default:
            return ""
    }
}

function getErrorActionByAction(action){
    switch (action.type){
        case STAGE_REQUEST: {
            return STAGE_REQUEST_FAILED
        }
        case GROUP_REQUEST: {
            return GROUP_REQUEST_FAILED
        }
        case FACTOR_REQUEST: {
            return FACTOR_REQUEST_FAILED
        }
        case INDICATOR_REQUEST: {
            return INDICATOR_REQUEST_FAILED
        }
        default:
            return ""
    }
}