import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import {
    CLOSE_MAIN_SIDEBAR,
    OPEN_MAIN_SIDEBAR,

} from "../../ducks/UserInterfaceReducer";
import {useStyles} from "../../jss/DashboardStyles";
import {Button, Grid, Typography} from "@material-ui/core";
import {AUTH_LOGOUT} from "../../ducks/AuthReducer";
import Divider from "@material-ui/core/Divider";
import {
    DICT_FACTORS,
    DICT_GROUPS, DICT_INDICATORS,
    DICT_STAGES,
    MAIN_SIDEBAR_WIDTH,
    PALETTE_SIDEBAR_WIDTH,
    TOP_PANEL_HEIGHT
} from "../../const/UIConst";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {NavLink} from "react-router-dom";
import {
    ROUTES_MAIN_MENU_DICTIONARY, ROUTES_MAIN_MENU_DICTIONARY_FACTOR,
    ROUTES_MAIN_MENU_DICTIONARY_GROUP, ROUTES_MAIN_MENU_DICTIONARY_INDICATOR, ROUTES_MAIN_MENU_DICTIONARY_STAGE,
    ROUTES_MAIN_MENU_FACTOR_MODEL_TABLE
} from "../../const/ConstRoutes";
import {useTranslation} from "react-i18next";

export const MainSidebar = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const sidebarOpened = useSelector(store => store.user_interface.mainSidebarOpen)

    const logout = () => dispatch({type: AUTH_LOGOUT})

    function toggleSidebar() {
        if (sidebarOpened === true) {
            dispatch({ type: CLOSE_MAIN_SIDEBAR });
        } else {
            dispatch({ type: OPEN_MAIN_SIDEBAR });
        }
    }

    return (
        <React.Fragment>
            <Drawer variant="persistent"
                    open={sidebarOpened}
                    anchor={"left"}>

                <Grid container style={{width:MAIN_SIDEBAR_WIDTH, position:"relative",minHeight:TOP_PANEL_HEIGHT}} alignItems={"center"} justify={"center"}>
                    <div style={{width:PALETTE_SIDEBAR_WIDTH, position:"absolute"}}>
                        <IconButton onClick={toggleSidebar} style={{float:"right"}}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </div>
                    <Grid item>
                        <Typography >
                            {t("mainMenu")}
                        </Typography>
                    </Grid>
                </Grid>

                <Divider/>

                <NavLink to={{
                    pathname:ROUTES_MAIN_MENU_DICTIONARY_GROUP,
                    dictType:DICT_GROUPS
                }}
                         style={{margin: "8px", textDecoration:"none"}}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.primaryBackgroundColor}
                        onClick={() => console.log('GROUPS')}
                        fullWidth
                    >
                        {t("groupsLabelCaps")}
                    </Button>
                </NavLink>

                <NavLink to={{
                    pathname:ROUTES_MAIN_MENU_DICTIONARY_FACTOR,
                    dictType:DICT_FACTORS
                }}
                         style={{margin: "8px", textDecoration:"none"}}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.primaryBackgroundColor}
                        onClick={() => console.log('FACTORS')}
                        fullWidth
                    >
                        {t("factorsLabelCaps")}
                    </Button>
                </NavLink>

                <NavLink to={{
                    pathname:ROUTES_MAIN_MENU_DICTIONARY_INDICATOR,
                    dictType:DICT_INDICATORS
                }}
                         style={{margin: "8px", textDecoration:"none"}}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.primaryBackgroundColor}
                        onClick={() => console.log('INDICATORS')}
                        fullWidth
                    >
                        {t("indicatorsLabelCaps")}
                    </Button>
                </NavLink>

                <NavLink to={{
                    pathname:ROUTES_MAIN_MENU_DICTIONARY_STAGE,
                    dictType:DICT_STAGES
                }}
                         style={{margin: "8px", textDecoration:"none"}}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.primaryBackgroundColor}
                        onClick={() => console.log('STAGES')}
                        fullWidth
                    >
                        {t("stagesLabelCaps")}
                    </Button>
                </NavLink>

                <NavLink to={ROUTES_MAIN_MENU_FACTOR_MODEL_TABLE}
                         style={{margin: "8px", textDecoration:"none"}}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.primaryBackgroundColor}
                        onClick={() => console.log('MODELS')}
                        fullWidth
                    >
                        {t("fmLabelCaps")}
                    </Button>
                </NavLink>
                <Divider/>
                {/*{error && <Alert severity='error' > {error} </Alert>}*/}
                <Grid
                    style={{margin: "8px"}}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.primaryBackgroundColor}
                        onClick={logout}

                    >
                        {t("logoutCaps")}
                    </Button>
                </Grid>

            </Drawer>
        </React.Fragment>
    )
}
