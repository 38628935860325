import React from "react";
import clsx from "clsx";
import CloseIcon from '@material-ui/icons/Close';
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import {useStyles} from "../../../jss/DashboardStyles";
import {INDICATOR_REMOVE} from "../../../ducks/FactorModelReducer";
import {useDispatch} from "react-redux";

export const SimpleCell = ({item, rowSpan, align, header, parentProps}) => {

    const classes = useStyles()
    const dispatch = useDispatch()

    const removeCell = (e) => {
        dispatch({type: INDICATOR_REMOVE, payload: {id: item.indicatorId, groupId: parentProps.groupId, factorId: parentProps.factorId} })
    }

    return(
        <TableCell
            className={
                clsx({
                    [classes.tableHeader]: header,
                    [classes.tableBorderPrimary]: true,
                    [classes.tableCellPrimary]: true,})}
            rowSpan={rowSpan}
            align={align ? align : "left"}
        >
            {item && item.name}
            {item &&
            <IconButton className={clsx({
                [classes.primaryColor]: !header,
                [classes.colorWhite]: header
            })}
                        size={"small"}
                        onClick={removeCell}
                        style={{float: "right"}}>
                <CloseIcon fontSize={"default"}/>
            </IconButton>
            }
        </TableCell>

    )
}

export const EmptyCell = () => {
    const classes = useStyles()
    return(
        <TableCell size={"small"} className={classes.tableBorderPrimary} style={{backgroundColor:"rgba(244, 244, 244, 1)"}}/>
    )
}