import React from 'react';
import {Grid} from "@material-ui/core";
import {Droppable} from "react-beautiful-dnd";
import {GROUP_DROPPABLE_ID} from "../../../const/UIConst";
import {useTranslation} from "react-i18next";

export const EmptyDesktop = () => {
    const { t } = useTranslation();
    return (
        <Droppable droppableId={"desktop"} type={GROUP_DROPPABLE_ID}>
            {(provided, snapshot) => {
                return(
                    <Grid container justify={"center"} alignItems={"center"}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                              border:"dashed",
                              borderColor:"lightgray",
                              background: snapshot.isDraggingOver ? '#FFA289' : 'white',
                              position:"fixed",
                              left:"50%",
                              top:"50%",
                              transform:"translate(-50%,-50%)",
                              height:"75vh",
                              width:"85vh"
                          }}
                    >
                        <Grid item
                              style={{
                                  color:"lightgray",
                              }}
                        >
                            {t("emptyDesktopPlaceholder")}
                        </Grid>
                        <div style={{maxWidth:"0px",maxHeight:"0px"}}>
                            {provided.placeholder}
                        </div>
                    </Grid>
                )
            }}
        </Droppable>
    )
}