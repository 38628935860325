import { takeLatest, put, call} from 'redux-saga/effects'
import {
    SET_APP_NAME,
    AUTH_CHECK,
    AUTH_FAILURE,
    AUTH_LOGOUT,
    AUTH_REQUEST,
    AUTH_SUCCESS, GET_APP_NAME,
    TOKEN_KEY
} from "../ducks/AuthReducer";
import {ROUTES_MAIN_MENU} from "../const/ConstRoutes";
import {getErrorMessage, simpleRequest, URL_AUTH, URL_GET_APP_NAME, URL_HEALTH_CHECK} from '../service/RequestService'
import {GLOBAL_ERROR} from "../ducks/UserInterfaceReducer";


export function* AuthSagaWatcher() {
    yield takeLatest(AUTH_REQUEST, postRequest)
    yield takeLatest(AUTH_LOGOUT, logout)
    yield takeLatest(AUTH_CHECK,authCheck)
    yield takeLatest(GET_APP_NAME, getAppName)
}

function* postRequest(action) {
    try {
        const  token  = yield call(simpleRequest,URL_AUTH ,'POST',null,{username: action.payload.username, password: action.payload.password})
        yield put({ type: AUTH_SUCCESS, payload: token.data })
        localStorage.setItem(TOKEN_KEY.toString(), JSON.stringify(token.data))
        action.payload.history.push(ROUTES_MAIN_MENU)
    } catch (error) {
        yield put({ type: AUTH_FAILURE, payload: getErrorMessage(error.response.status) })
        yield localStorage.removeItem(TOKEN_KEY.toString())
    }
}

function* logout(){
    localStorage.removeItem(TOKEN_KEY.toString())
    yield null
}

export function* authCheck() {
    try {
        yield call(simpleRequest,URL_HEALTH_CHECK ,'GET',null,null)
    } catch (error) {
        if (error.response.status === 401){
            console.log('Bad auth, need logout.')
            yield put ({type:AUTH_LOGOUT})
        }
    }
}

function* getAppName() {
    try {
        const response  = yield call(simpleRequest, URL_GET_APP_NAME ,'GET',null,null)
        yield put({ type: SET_APP_NAME, payload: response.data });
    } catch (error) {
        yield put({ type: GLOBAL_ERROR, payload: getErrorMessage(error.response.status) });
    }
}