import { takeLatest, put, call, select} from 'redux-saga/effects'
import {
    getErrorMessage,
    simpleRequest, URL_DELETE_DELETE_FACTOR_MODEL, URL_GET_FACTOR_MODEL, URL_GET_TABLE_PAGE, URL_POST_COPY_FACTOR_MODEL,
} from "../service/RequestService";
import {
    REQUEST_FM,
    REQUEST_FM_COPY,
    REQUEST_FM_COPY_SUCCESS,
    REQUEST_FM_DELETE, REQUEST_FM_DELETE_SUCCESS, REQUEST_FM_ERROR, REQUEST_FM_SUCCESS,
    REQUEST_FM_TABLE_PAGE,
    REQUEST_FM_TABLE_PAGE_SUCCESS
} from "../ducks/FactorModelTableReducer";
import {GLOBAL_ERROR} from "../ducks/UserInterfaceReducer";
import {SET_FACTOR_MODEL} from "../ducks/FactorModelReducer";

export function* FactorModelSagaWatcher() {
    yield takeLatest(REQUEST_FM, getRequestFM)
    yield takeLatest(REQUEST_FM_TABLE_PAGE, getRequestTablePage)
    yield takeLatest(REQUEST_FM_DELETE, getRequestDeleteFM)
    yield takeLatest(REQUEST_FM_COPY, getRequestCopyFM)
}

function* getRequestFM(action){
    try {
        const  response  = yield call(simpleRequest, URL_GET_FACTOR_MODEL,'GET',action.payload,null);
        yield put({ type: REQUEST_FM_SUCCESS });
        yield put({ type: SET_FACTOR_MODEL, payload: response.data });
    } catch (error) {
        yield put({ type: REQUEST_FM_ERROR });
        yield put({ type: GLOBAL_ERROR, payload: getErrorMessage(error.response.status) });
    }
}

function* getRequestTablePage(action){
    try {
        const  response  = yield call(simpleRequest, URL_GET_TABLE_PAGE,'GET',action.payload,null);
        yield put({ type: REQUEST_FM_TABLE_PAGE_SUCCESS, payload: response.data });
    } catch (error) {
        yield put({ type: GLOBAL_ERROR, payload: getErrorMessage(error.response.status) });
    }
}

function* getRequestDeleteFM(action){
    try {
        const  response  = yield call(simpleRequest, URL_DELETE_DELETE_FACTOR_MODEL,'DELETE',action.payload,null);
        yield put({ type: REQUEST_FM_DELETE_SUCCESS, payload: response.data });
        const rowsPerPage = yield select(store => store.factorModelTable.rowsPerPage)
        const pageNumber = yield select(store => store.factorModelTable.pageNumber)
        yield put({type: REQUEST_FM_TABLE_PAGE, payload: {page: pageNumber, size: rowsPerPage}})
    } catch (error) {
        yield put({ type: GLOBAL_ERROR, payload: getErrorMessage(error.response.status) });
    }
}

function* getRequestCopyFM(action){
    try {
        const  response  = yield call(simpleRequest, URL_POST_COPY_FACTOR_MODEL,'POST',action.payload,null);
        yield put({ type: REQUEST_FM_COPY_SUCCESS, payload: response.data });
        const rowsPerPage = yield select(store => store.factorModelTable.rowsPerPage)
        const pageNumber = yield select(store => store.factorModelTable.pageNumber)
        yield put({type: REQUEST_FM_TABLE_PAGE, payload: {page: pageNumber, size: rowsPerPage}})
    } catch (error) {
        yield put({ type: GLOBAL_ERROR, payload: getErrorMessage(error.status) });
    }
}