import {applyMiddleware, combineReducers, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import {composeWithDevTools} from "redux-devtools-extension";
import {AuthReducer} from "../ducks/AuthReducer";
import {AuthSagaWatcher} from "../sagas/AuthSagaWatcher";
import {UserInterfaceReducer} from "../ducks/UserInterfaceReducer";
import {PaletteReducer} from "../ducks/PaletteReducer";
import {PaletteSagaWatcher} from "../sagas/PaletteSagaWatcher";
import {FactorModelReducer} from "../ducks/FactorModelReducer";
import {SidebarSettingsReducer} from "../ducks/SidebarSettingsReducer";
import {SidebarSettingsSagaWatcher} from "../sagas/SidebarSettingsSagaWatcher";
import {FactorModelTableReducer} from "../ducks/FactorModelTableReducer";
import {FactorModelSagaWatcher} from "../sagas/FactorModelSagaWatcher";
import {UISagaWatcher} from "../sagas/UISagaWatcher";
import {DictionaryTableReducer} from "../ducks/DictionaryTableReducer";
import {DictionarySagaWatcher} from "../sagas/DictionarySagaWatcher";

const reducer = combineReducers({
    login: AuthReducer,
    user_interface: UserInterfaceReducer,
    palette: PaletteReducer,
    desktop: FactorModelReducer,
    sideBarSettings: SidebarSettingsReducer,
    factorModelTable: FactorModelTableReducer,
    dictionaryTable: DictionaryTableReducer,
});

const saga = createSagaMiddleware();

const store = createStore(reducer, composeWithDevTools(applyMiddleware(saga)));

saga.run(AuthSagaWatcher);
saga.run(PaletteSagaWatcher);
saga.run(SidebarSettingsSagaWatcher);
saga.run(FactorModelSagaWatcher);
saga.run(DictionarySagaWatcher);
saga.run(UISagaWatcher);

export default store;