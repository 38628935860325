export const STAGE_REQUEST = "STAGE_REQUEST";
export const STAGE_REQUEST_SUCCESS = "STAGE_REQUEST_SUCCESS";
export const STAGE_REQUEST_FAILED = "STAGE_REQUEST_FAILED";
export const GROUP_REQUEST = "GROUP_REQUEST";
export const GROUP_REQUEST_SUCCESS = "GROUP_REQUEST_SUCCESS";
export const GROUP_REQUEST_FAILED = "GROUP_REQUEST_FAILED";
export const FACTOR_REQUEST = "FACTOR_REQUEST";
export const FACTOR_REQUEST_SUCCESS = "FACTOR_REQUEST_SUCCESS";
export const FACTOR_REQUEST_FAILED = "FACTOR_REQUEST_FAILED";
export const INDICATOR_REQUEST = "INDICATOR_REQUEST";
export const INDICATOR_REQUEST_SUCCESS = "INDICATOR_REQUEST_SUCCESS";
export const INDICATOR_REQUEST_FAILED = "INDICATOR_REQUEST_FAILED";

const initialState = {
    stage_list: [],
    group_list: [],
    factor_list: [],
    indicator_list: [],
    error: null,
};

export const PaletteReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STAGE_REQUEST_SUCCESS: {
            return { ...state, stage_list: payload, error: null };
        }
        case GROUP_REQUEST_SUCCESS: {
            return { ...state, group_list: payload, error: null };
        }
        case FACTOR_REQUEST_SUCCESS: {
            return { ...state, factor_list: payload, error: null };
        }
        case INDICATOR_REQUEST_SUCCESS: {
            return { ...state, indicator_list: payload, error: null };
        }
        case STAGE_REQUEST_FAILED:
        case GROUP_REQUEST_FAILED:
        case FACTOR_REQUEST_FAILED:
        case INDICATOR_REQUEST_FAILED: {
            return { ...state, error: payload };
        }
        default:
            return state;
    }
};