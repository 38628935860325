export const CHECK_INDICATORS_IN_TEMPLATES_REQUEST = "CHECK_INDICATORS_IN_TEMPLATES_REQUEST"
export const CHECK_INDICATORS_IN_TEMPLATES_REQUEST_SUCCESS = "CHECK_INDICATORS_IN_TEMPLATES_REQUEST_SUCCESS";
export const CHECK_INDICATORS_IN_TEMPLATES_REQUEST_FAILED = "CHECK_INDICATORS_IN_TEMPLATES_REQUEST_FAILED";

export const ACCELERATORS_REQUEST = "ACCELERATORS_REQUEST"
export const ACCELERATORS_REQUEST_SUCCESS = "ACCELERATORS_REQUEST_SUCCESS";
export const ACCELERATORS_REQUEST_FAILED = "ACCELERATORS_REQUEST_FAILED";

export const ACCELERATOR_PROGRAMS_REQUEST = "ACCELERATOR_PROGRAMS_REQUEST"
export const ACCELERATOR_PROGRAMS_REQUEST_SUCCESS = "ACCELERATOR_PROGRAMS_REQUEST_SUCCESS";
export const ACCELERATOR_PROGRAMS_REQUEST_FAILED = "ACCELERATOR_PROGRAMS_REQUEST_FAILED";

export const FACTOR_MODEL_CREATE_REQUEST = "FACTOR_MODEL_CREATE_REQUEST"
export const FACTOR_MODEL_CREATE_REQUEST_SUCCESS = "FACTOR_MODEL_CREATE_REQUEST_SUCCESS"
export const FACTOR_MODEL_CREATE_REQUEST_FAILED = "FACTOR_MODEL_CREATE_REQUEST_FAILED"

export const MODAL_CHECK_INDICATORS_OPEN = "MODAL_CHECK_INDICATORS_OPEN"
export const MODAL_CHECK_INDICATORS_CLOSE = "MODAL_CHECK_INDICATORS_CLOSE"

export const MODAL_CHECK_MODEL_OPEN = "MODAL_CHECK_MODEL_OPEN"
export const MODAL_CHECK_MODEL_CLOSE = "MODAL_CHECK_MODEL_CLOSE"

export const CHECK_MODEL = "CHECK_MODEL"

const initialState = {
    acceleratorList: [],
    acceleratorProgramTree: {},
    problematicIndicators: [],
    showModalCheckIndicators: false,
    problematicModel: [],
    showModalCheckModel: false,
    error: null
};

export const SidebarSettingsReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case CHECK_MODEL: {
            return {...state, problematicModel: payload, error: null}
        }
        case CHECK_INDICATORS_IN_TEMPLATES_REQUEST_SUCCESS: {
            return {...state, problematicIndicators: payload, error: null}
        }
        case ACCELERATORS_REQUEST_SUCCESS: {
            return {...state, acceleratorList: payload, error: null}
        }
        case ACCELERATOR_PROGRAMS_REQUEST_SUCCESS: {
            return {...state, acceleratorProgramTree: payload, error: null}
        }
        case CHECK_INDICATORS_IN_TEMPLATES_REQUEST:
        case ACCELERATORS_REQUEST_FAILED:
        case ACCELERATOR_PROGRAMS_REQUEST_FAILED: {
            return {...state, error: payload};
        }
        case MODAL_CHECK_INDICATORS_OPEN: {
            return {...state, showModalCheckIndicators: true}
        }
        case MODAL_CHECK_INDICATORS_CLOSE: {
            return {...state, showModalCheckIndicators: false}
        }
        case MODAL_CHECK_MODEL_OPEN: {
            return {...state, showModalCheckModel: true}
        }
        case MODAL_CHECK_MODEL_CLOSE: {
            return {...state, showModalCheckModel: false}
        }
        default:
            return state;
    }
};