import React, {useState} from 'react';
import {useDispatch, connect, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {useStyles} from "../../jss/LoginStyles"
import {AUTH_REQUEST} from "../../ducks/AuthReducer";
import logoImg from "../../logo-ico.svg"
import {LaptopIcon} from "../icons/Icons";
import {useTranslation} from "react-i18next";
import {LanguagePicker} from "../LanguagePicker";

const LoginPage = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const {error, history } = props
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const appName = useSelector(store => store.login.appName)

    const signIn = (event) => {
        event.preventDefault()
        dispatch(
            {
                type: AUTH_REQUEST,
                payload:  {username: username, password: password, history: history}
            }
        )
    }
    
    return(
        <Grid container component="main" className={classes.root}>
            <Grid container item xs={12} sm={4} md={8} component={Paper} justify="center" alignItems="center">
                <Grid container item xs={12} sm={12} md={12} className={classes.middleContent} justify="center" alignItems="center">
                    {appName === 'BusinessChain' &&
                    <Grid item>
                        <img className="App-project-logo" src={"/bch-logo"} alt={"Project logo"}/>
                    </Grid>}
                    <Grid item xs={12} sm={12} md={12}>
                        <Typography component="h1" variant="h4" align={"center"}>
                            FactorModel
                        </Typography>
                    </Grid>
                    <LaptopIcon/>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <div style={{
                        position:"absolute",
                        left:"100%",
                        top:"0%",
                        transform:"translate(-175%,0%)",
                    }}>
                        <LanguagePicker/>
                    </div>
                    <Typography component="h1" variant="h5" align={"center"}>
                        {t("signIn")}
                    </Typography>
                    {error && <Alert severity='error'> Invalid UserName or Password </Alert>}
                    <form className={classes.form} onSubmit={signIn}>
                        <TextField label={t("userName")}
                                   type="username"
                                   placeholder={t("userName")}
                                   value={username}
                                   fullWidth
                                   autoFocus={true}
                                   onChange={ e => {setUsername(e.target.value)}}
                        />
                        <TextField label={t("password")}
                                   type="password"
                                   placeholder={t("password")}
                                   value={password}
                                   fullWidth
                                   onChange={ e => setPassword(e.target.value)}
                        />
                        <Button
                            type="submit"
                            disabled={false}
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}>
                            {t("signInCaps")}
                        </Button>
                    </form>

                </div>
            </Grid>
        </Grid>
    )
}

export default connect((store) => ({
    error: store.login.error
}), null)(LoginPage)