import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        backgroundColor: "#ff5629",
        margin: theme.spacing(3, 0, 2),
    },
    primaryBackgroundColor: {
        backgroundColor: "#ff5629",
    },
    primaryColor: {
        color: "#ff5629",
    },
    label: {
        "&$focusedLabel": {
            color: "#ff5629"
        },
    },
    focusedLabel: {},
    middleContent: {
        display: "flex",
        alignItems: "center"
    },
}));