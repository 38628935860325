export const ROUTES_LOGIN = "/loginpage"
export const ROUTES_DASHBOARD = "/dashboard"
export const ROUTES_HOME = "/"
export const ROUTES_MAIN_MENU = "/mainmenu"
export const ROUTES_MAIN_MENU_DICTIONARY = "/mainmenu/dictionary"
export const ROUTES_MAIN_MENU_DICTIONARY_GROUP = "/mainmenu/dictionary/group"
export const ROUTES_MAIN_MENU_DICTIONARY_FACTOR = "/mainmenu/dictionary/factor"
export const ROUTES_MAIN_MENU_DICTIONARY_INDICATOR = "/mainmenu/dictionary/indicator"
export const ROUTES_MAIN_MENU_DICTIONARY_STAGE = "/mainmenu/dictionary/stage"
export const ROUTES_MAIN_MENU_FACTOR_MODEL_TABLE = "/mainmenu/factormodeltable"
