export const PALETTE_SIDEBAR_WIDTH = "300px"
export const SETTINGS_SIDEBAR_WIDTH = "300px"
export const MAIN_SIDEBAR_WIDTH = "300px"
export const SIDEBAR_MINIMAL_WIDTH = "48px"
export const TOP_PANEL_HEIGHT = "48px"
export const PALETTE_DICT_LIST_HEIGHT = "200px"
export const SIDEBAR_LIST_ITEM_WIDTH = "230px"

export const STAGE_DROPPABLE_ID = "STAGE_DROPPABLE"
export const GROUP_DROPPABLE_ID = "GROUP_DROPPABLE"
export const FACTOR_DROPPABLE_ID = "FACTOR_DROPPABLE"
export const INDICATOR_DROPPABLE_ID = "INDICATOR_DROPPABLE"

export const DICT_GROUPS = "DICT_GROUPS"
export const DICT_FACTORS = "DICT_FACTORS"
export const DICT_INDICATORS = "DICT_INDICATORS"
export const DICT_STAGES = "DICT_STAGES"
export const FACTOR_MODEL = "FACTOR_MODEL"

export const CONST_LANGUAGE_RU = "ru"
export const CONST_LANGUAGE_EN = "en"

export const PRIMARY_COLOR = "#ff5629";