import {
    getNewFactorModelAfterAddFactor,
    getNewFactorModelAfterAddGroup,
    getNewFactorModelAfterAddIndicator,
    getNewFactorModelAfterAddNewStage,
    getNewFactorModelAfterDeleteFactor,
    getNewFactorModelAfterDeleteGroup,
    getNewFactorModelAfterDeleteIndicator,
    getNewFactorModelAfterDeleteStage,
    setStopFactorByCoordinates,
    setWeightByCoordinates
} from "../service/FactorModelService";

export const SET_FACTOR_MODEL = "SET_FACTOR_MODEL";
export const SET_EMPTY_FACTOR_MODEL = "SET_EMPTY_FACTOR_MODEL";
export const SET_FACTOR_MODEL_CODE = "SET_FACTOR_MODEL_CODE";
export const SET_FACTOR_MODEL_NAME = "SET_FACTOR_MODEL_NAME";
export const SET_FACTOR_MODEL_DESCRIPTION = "SET_FACTOR_MODEL_DESCRIPTION";
export const SET_FACTOR_MODEL_STATUS = "SET_FACTOR_MODEL_STATUS";
export const SET_FACTOR_MODEL_ACCELERATOR_ID = "SET_FACTOR_MODEL_ACCELERATOR_ID";
export const SET_FACTOR_MODEL_TRACK_POINTS = "SET_FACTOR_MODEL_TRACK_POINTS";
export const SET_FACTOR_MODEL_POINT_WEIGHT = "SET_FACTOR_MODEL_POINT_WEIGHT";
export const SET_FACTOR_MODEL_POINT_STOP_FACTOR = "SET_FACTOR_MODEL_POINT_STOP_FACTOR";
export const STAGE_ADD = "STAGE_ADD";
export const STAGE_REMOVE = "STAGE_REMOVE";
export const GROUP_ADD = "GROUP_ADD";
export const GROUP_REMOVE = "GROUP_REMOVE";
export const FACTOR_ADD = "FACTOR_ADD";
export const FACTOR_REMOVE = "FACTOR_REMOVE";
export const INDICATOR_ADD = "INDICATOR_ADD";
export const INDICATOR_REMOVE = "INDICATOR_REMOVE";

const defaultInitialState = {
    factorModel: {
        id: null,
        code: '',
        name: '',
        status: 'DRAFT',
        description: '',
        acceleratorId: null,
        trackPoints: [],
        groups: [],
        stages: [],
        stateMessage:null
    },
    error: null,
};

const initialState = defaultInitialState

export const FactorModelReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_FACTOR_MODEL: {
            return {...state, factorModel: payload}
        }
        case SET_EMPTY_FACTOR_MODEL: {
            return {...state, factorModel: initialState.factorModel}
        }
        case STAGE_ADD: {
            return {...state, factorModel: getNewFactorModelAfterAddNewStage(payload, state.factorModel)}
        }
        case STAGE_REMOVE: {
            return {...state, factorModel: getNewFactorModelAfterDeleteStage(payload, state.factorModel)}
        }
        case GROUP_ADD: {
            return {...state, factorModel: getNewFactorModelAfterAddGroup(payload, state.factorModel)}
        }
        case GROUP_REMOVE: {
            return {...state, factorModel: getNewFactorModelAfterDeleteGroup(payload, state.factorModel)}
        }
        case FACTOR_ADD: {
            return {...state, factorModel: getNewFactorModelAfterAddFactor(payload, state.factorModel)}
        }
        case FACTOR_REMOVE: {
            return {...state, factorModel: getNewFactorModelAfterDeleteFactor(payload, state.factorModel)}
        }
        case INDICATOR_ADD: {
            return {...state, factorModel: getNewFactorModelAfterAddIndicator(payload, state.factorModel)}
        }
        case INDICATOR_REMOVE: {
            return {...state, factorModel: getNewFactorModelAfterDeleteIndicator(payload, state.factorModel)}
        }
        case SET_FACTOR_MODEL_STATUS: {
            return {
                ...state, factorModel: {
                    ...state.factorModel, status: payload
                }
            }
        }
        case SET_FACTOR_MODEL_CODE: {
            return {
                ...state, factorModel: {
                    ...state.factorModel, code: payload
                }
            }
        }
        case SET_FACTOR_MODEL_NAME: {
            return {
                ...state, factorModel: {
                    ...state.factorModel, name: payload
                }
            }
        }
        case SET_FACTOR_MODEL_DESCRIPTION: {
            return {
                ...state, factorModel: {
                    ...state.factorModel, description: payload
                }
            }
        }
        case SET_FACTOR_MODEL_ACCELERATOR_ID: {
            return {
                ...state, factorModel: {
                    ...state.factorModel, acceleratorId: payload
                }
            }
        }
        case SET_FACTOR_MODEL_TRACK_POINTS: {
            return {
                ...state, factorModel: {
                    ...state.factorModel, trackPoints: payload
                }
            }
        }
        case SET_FACTOR_MODEL_POINT_WEIGHT: {
            return setWeightByCoordinates(state,payload)
        }
        case SET_FACTOR_MODEL_POINT_STOP_FACTOR: {
            return setStopFactorByCoordinates(state,payload)
        }
        default:
            return state;
    }
};

