import React from "react";
import {useDispatch} from "react-redux";
import Modal from "@material-ui/core/Modal";
import {Button, CardContent, CardHeader} from "@material-ui/core";
import {useStyles} from "../../jss/DashboardStyles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";

export const SimpleModal = ({open, closeAction, okAction, okCaption, caption, okDisabled, children}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation();

    return(
        <Modal  open={open}
                disableAutoFocus={true}
                style={{display:'flex',alignItems:'center',justifyContent:'center', outline: 'none'}}
        >
            <Card style={{
                width: 345,
                outline: 'none'
            }}
            >
                <CardHeader
                    className={classes.primaryBackgroundColor}
                    title={caption}
                    color={"white"}
                    titleTypographyProps={{variant:"h6", align:"center", }}
                    style={{color:"white"}}
                />
                <CardContent>
                    {children}
                </CardContent>
                <CardActions>
                    <Grid container justify={"center"} alignItems={"center"}>
                        <Grid container item xs={12} justify={"center"} alignItems={"center"}>

                        </Grid>
                        <Grid container item xs={6} justify={"center"}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.primaryBackgroundColor}
                                onClick={(e) => dispatch({type:closeAction})}
                                style={{width:"120px"}}
                            >
                                {t("modalCANCEL")}
                            </Button>
                        </Grid>
                        <Grid container item xs={6} justify={"center"}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.primaryBackgroundColor}
                                onClick={(e) => okAction(e)}
                                style={{width:"120px"}}
                                disabled={okDisabled}
                            >
                                {okCaption ? okCaption : t("OK")}
                            </Button>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Modal>
    )
}