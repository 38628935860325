import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import {Button, Grid} from "@material-ui/core";
import {useStyles} from "../../jss/DashboardStyles";
import {
    MODAL_COPY_CLOSE, MODAL_COPY_SHOW,
    MODAL_DELETE_CLOSE,
    MODAL_DELETE_SHOW, REQUEST_FM, REQUEST_FM_COPY, REQUEST_FM_DELETE,
    REQUEST_FM_TABLE_PAGE, SET_PAGE_NUMBER, SET_ROWS_PER_PAGE
} from "../../ducks/FactorModelTableReducer";
import {ROUTES_DASHBOARD} from "../../const/ConstRoutes";
import TablePagination from "@material-ui/core/TablePagination";
import {SimpleModal} from "../modal/Modals";
import {NavLink} from "react-router-dom";
import {SET_ACTIVE_MENU_ITEM} from "../../ducks/UserInterfaceReducer";
import {FACTOR_MODEL} from "../../const/UIConst";
import {SET_EMPTY_FACTOR_MODEL} from "../../ducks/FactorModelReducer";
import {useTranslation} from "react-i18next";

export const FactorModelTable = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const factorModelResult = useSelector(store => store.factorModelTable.factorModelResult)
    const showModalCopyFrom = useSelector(store => store.factorModelTable.showModalCopyFrom)
    const showModalDelete = useSelector(store => store.factorModelTable.showModalDelete)
    const rowsPerPage = useSelector(store => store.factorModelTable.rowsPerPage)
    const pageNumber = useSelector(store => store.factorModelTable.pageNumber)

    const [selectedIds,setSelectedIds] = useState([])
    const [searchString, setSearchString] = useState("")
    const [copyName, setCopyName] = useState("")

    useEffect(()=>{
        dispatch({ type: MODAL_COPY_CLOSE })
        dispatch({ type: MODAL_DELETE_CLOSE })
    },[])//пустой массив зависимостей позволяет запустить useEffect только один раз

    const isInSelected = (id) => {
        return selectedIds.includes(id)
    }
    const toggleSelected = (id) => {
        if (selectedIds.includes(id))
            setSelectedIds(selectedIds.filter(x => x !== id))
        else
            setSelectedIds(selectedIds.concat([id]))
    }
    const handleChangePage = (event, newPage) => {
        dispatch({type: SET_PAGE_NUMBER, payload: newPage});
    };
    const handleChangeRowsPerPage = (event) => {
        dispatch({type: SET_ROWS_PER_PAGE, payload: parseInt(event.target.value, 10)});
        dispatch({type: SET_PAGE_NUMBER, payload: 0});
    };

    useEffect(() => {
        dispatch({type: REQUEST_FM_TABLE_PAGE, payload: {page: pageNumber, size: rowsPerPage, name: searchString}})
        dispatch({type: SET_ACTIVE_MENU_ITEM, payload: FACTOR_MODEL})
    },[pageNumber,rowsPerPage,dispatch])

    const textSearch = () => {
        dispatch({type: REQUEST_FM_TABLE_PAGE, payload: {page: pageNumber, size: rowsPerPage, name: searchString}})
    }

    return(
        <>
            <Grid container item xs={12} justify={"flex-end"}>
                <TextField
                    label={t("searchText")}
                    placeholder={t("searchText")}
                    variant={"outlined"}
                    size={"small"}
                    style={{margin:"8px",}}
                    className={classes.primaryBorderOutline}
                    onChange={(e) => setSearchString(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.primaryBackgroundColor}
                    onClick={(e) => {
                        e.preventDefault()
                        textSearch()
                    }}
                    style={{margin:"8px 8px 8px -8px"}}
                >
                    <SearchIcon/>
                </Button>

                <NavLink to={ROUTES_DASHBOARD}
                         style={{textDecoration:"none"}}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.primaryBackgroundColor}
                        style={{margin:"8px"}}
                        onClick={()=>{ dispatch({type:SET_EMPTY_FACTOR_MODEL}) }}
                    >
                        <AddBoxIcon/>
                    </Button>
                </NavLink>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.primaryBackgroundColor}
                    onClick={() => {
                        setCopyName("")
                        dispatch({type:MODAL_COPY_SHOW})
                    }}
                    style={{margin:"8px"}}
                    disabled={selectedIds.length!==1}
                >
                    <FileCopyIcon/>
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.primaryBackgroundColor}
                    onClick={(e) => dispatch({type:MODAL_DELETE_SHOW})}
                    style={{margin:"8px"}}
                    disabled={selectedIds.length<1}
                >
                    <DeleteIcon/>
                </Button>
            </Grid>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHeader} align="left"/>
                            <TableCell className={classes.tableHeader} align="left">{t("name")}</TableCell>
                            <TableCell className={classes.tableHeader} align="left">{t("accelerator")}</TableCell>
                            <TableCell className={classes.tableHeader} align="left">{t("status")}</TableCell>
                            <TableCell className={classes.tableHeader} align="left">{t("copyFrom")}</TableCell>
                            <TableCell className={classes.tableHeader} align="left"/>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {factorModelResult.items && factorModelResult.items.map((fmItem, index)=> {
                            return(
                                <TableRow key={index} role="checkbox"
                                    style={{backgroundColor: isInSelected(fmItem.id) ? "rgba(244, 244, 244, 1)" : "white"}}
                                    onClick={(e) => {
                                        e.preventDefault()
                                        toggleSelected(fmItem.id)
                                    }}
                                >
                                    <TableCell size={"small"} padding="checkbox">
                                        <Checkbox
                                            className={classes.primaryCheckBox}
                                            checked={isInSelected(fmItem.id)}
                                        />
                                    </TableCell>
                                    <TableCell size={"small"}>
                                        {fmItem.name}
                                    </TableCell>
                                    <TableCell size={"small"}>
                                        {fmItem.acceleratorId}
                                    </TableCell>
                                    <TableCell size={"small"}>
                                        {fmItem.status}
                                    </TableCell>
                                    <TableCell size={"small"}>
                                        {fmItem.parentFactorModel && fmItem.parentFactorModel.name}
                                    </TableCell>
                                    <TableCell size={"small"} align={"center"}>
                                        <NavLink to={ROUTES_DASHBOARD} //TODO factorModelId request
                                                 style={{textDecoration:"none"}}
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.primaryBackgroundColor}
                                                style={{}}
                                                onClick={()=>{ dispatch({type:REQUEST_FM,payload:{id: fmItem.id}}) }}
                                            >
                                                <EditIcon/>
                                            </Button>
                                        </NavLink>
                                    </TableCell>
                                </TableRow>
                            )
                        })}

                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={factorModelResult.totalItems ? factorModelResult.totalItems : 0}
                rowsPerPage={rowsPerPage}
                page={pageNumber}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={t("labelRowsPerPage")}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} `+t("labelDisplayedRowsMiddle")+` ${count}`}
            />

            <SimpleModal
                open={showModalCopyFrom}
                caption={t("modalCopyFMCaption")}
                closeAction={MODAL_COPY_CLOSE}
                okAction={(e) => {
                    e.preventDefault()
                    dispatch({type:REQUEST_FM_COPY, payload: {factorModelId: selectedIds[0], name: copyName}})
                    dispatch({type:MODAL_COPY_CLOSE})
                }}
                okCaption={t("modalCOPY")}
                okDisabled={copyName.length===0}
            >
                <TextField
                    label={t("newFMName")}
                    placeholder={t("newFMName")}
                    variant={"outlined"}
                    size={"small"}
                    fullWidth
                    className={classes.primaryBorderOutline}
                    onChange={(e) => setCopyName(e.target.value)}
                />
            </SimpleModal>

            <SimpleModal
                open={showModalDelete}
                caption={t("modalDeleteCaption")}
                closeAction={MODAL_DELETE_CLOSE}
                okAction={(e) => {
                    e.preventDefault()
                    for (let id of selectedIds){
                        dispatch({type:REQUEST_FM_DELETE, payload: {factorModelId: id}})
                    }
                    setSelectedIds([])
                    dispatch({type:MODAL_DELETE_CLOSE})
                }}
                okCaption={t("modalDELETE")}
            />
        </>
    )
}