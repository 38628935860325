import React from "react";


export const LaptopIcon = () => {
    return (
        <div className="img-wrap">
            <svg xmlns="http://www.w3.org/2000/svg" className="img-injectable img-svg"
                 data-name="img-investor-invitation" width="480" height="400" viewBox="0 0 480 400"
                 data-inject-url="https://businesschain.io/img/temp/img-investor-invitation.svg">
                <path
                    d="M438.5 181.7c5.9-13.3 9.2-28 9.2-43.3 0-60.6-50.6-109.8-113-109.8-32 0-60.9 12.9-81.4 33.7-24-22.6-57.6-36.7-94.8-36.7-72.8 0-131.8 53.7-131.8 120 0 22 6.5 42.6 17.8 60.3-24.8 17.8-40.3 42.9-40.3 70.6 0 54.1 58.7 97.9 131 97.9 37.8 0 71.8-11.9 95.7-31.1 11.4 16.7 36.2 28.2 65.1 28.2 16.8 0 32.3-3.9 44.5-10.5 11.6 5.2 24.2 8 37.5 8 54 0 97.8-47 97.8-105-.1-33.3-14.6-63.1-37.3-82.3z"
                    fill="#fff"/>
                <path d="M85.8 97.8h303.7v191H85.8v-191z" fill="#efeff3"/>
                <path d="M46 317.1l-17.1-7h416.3l-18.7 7H46z" fill="#c6cdd7"/>
                <path d="M33.7 296.2h407.7c2.2 0 4.1 1.8 4.1 4.1v9.9h-416s.2-5.8.2-10c0-2.2 1.8-4 4-4z"
                      fill="#e7e8ea"/>
                <path d="M170.7 283.7h-74c-1.9 0-3.5-1.6-3.5-3.5V109.7c0-1.9 1.6-3.5 3.5-3.5h74v177.5z"
                      fill="#fff"/>
                <path
                    d="M105.2 142.4h42v4.3h-42v-4.3zm0 10h42v4.3h-42v-4.3zm0 32h42v4.3h-42v-4.3zm0 10h42v4.3h-42v-4.3z"
                    fill="#ebedf3"/>
                <path d="M92.5 104.4h294.8v21.3H92.5v-21.3z" fill="#fff"/>
                <path
                    d="M389 92.1H86.7c-3 0-5.5 2.1-5.5 5.2v198.8h313V97.3c0-3.1-2.1-5.2-5.2-5.2zm-7.8 188.3c0 2-1.6 3.7-3.7 3.7H96.9c-2 0-3.7-1.6-3.7-3.7V109.8c0-2 1.6-3.7 3.7-3.7h280.7c2 0 3.7 1.6 3.7 3.7v170.6h-.1z"
                    fill="#3c4855"/>
                <path
                    d="M256.1 141.6h-65.8c-1.7 0-3.1 1.4-3.1 3.1V198c0 1.7 1.4 3.1 3.1 3.1h65.8c1.7 0 3.1-1.4 3.1-3.1v-53.3c0-1.7-1.4-3.1-3.1-3.1zm21.1 1.8h46v7h-46v-7zm0 18h82.5v7h-82.5v-7zm0 78h82.5v7h-82.5v-7zm0 17h82.5v7h-82.5v-7zm-90-17h71.3v7h-71.3v-7zm0 17h71.3v7h-71.3v-7zm-16.2-34h209.8v1H171v-1z"
                    fill="#dcdfe9"/>
                <path
                    d="M358 200.9h-80c-.8 0-1.5-.7-1.5-1.5v-17.5c0-.8.7-1.5 1.5-1.5h80c.8 0 1.5.7 1.5 1.5v17.5c0 .8-.7 1.5-1.5 1.5z"
                    fill="#ff5629" className="fill-primary"/>
                <path
                    d="M201.2 114.6H221v3h-19.8v-3zm61.5.2h19.8v3h-19.8v-3zm80.5-.2h26.2v3h-26.2v-3zm-110.5.2h19.8v3h-19.8v-3zm-130.5-.2H136v3h-33.8v-3z"
                    fill="#646475"/>
                <path
                    d="M385.8 108.2c-20.6 0-37.3 14-37.3 31.2 0 11.8 7.9 22.1 19.5 27.4l-1.7 9.5 14.6-6c1.6.2 3.2.3 4.9.3 20.6 0 37.3-14 37.3-31.2s-16.7-31.2-37.3-31.2z"
                    fill="#ff5629" className="fill-primary"/>
                <g fill="#fff">
                    <path
                        d="M399.9 131.8h-25.3l12.6 11.2 12.7-11.2zm1.4 1.1l-9 7.9 8.9 7.8c.1-.3.2-.5.2-.8v-14.3c0-.2 0-.4-.1-.6zm-28.2-.1c-.1.2-.1.4-.1.7v14.3c0 .3.1.5.2.7l9-7.7-9.1-8z"/>
                    <path d="M387.2 145.4l-3.8-3.4-8.8 7.6h25l-8.6-7.5-3.8 3.3z"/>
                </g>
                <path
                    d="M343.9 216.6L333 205.7l7.8-3.9c.8-.4.7-1.6-.1-1.9l-24.5-8.2c-.7-.2-1.4.4-1.2 1.1l8.2 24.7c.3.8 1.3.8 1.7.1l4-8 10.9 10.9c.7.7 1.8.7 2.5 0l1.6-1.6c.7-.5.7-1.6 0-2.3z"
                    fill="#fff"/>
                <path
                    d="M343.9 216.6L333 205.7l7.8-3.9c.8-.4.7-1.6-.1-1.9l-24.5-8.2c-.7-.2-1.4.4-1.2 1.1l8.2 24.7c.3.8 1.3.8 1.7.1l4-8 10.9 10.9c.7.7 1.8.7 2.5 0l1.6-1.6c.7-.5.7-1.6 0-2.3z"
                    fill="none" stroke="#c6cdd7" strokeMiterlimit="10"/>
            </svg>
        </div>
    )
}