import React, {useEffect} from 'react';
import './App.css';
import LoginPage from "./components/loginpage/LoginPage";
import {HashRouter, Switch, Route, Redirect} from "react-router-dom";
import {ROUTES_DASHBOARD, ROUTES_LOGIN, ROUTES_MAIN_MENU} from "./const/ConstRoutes";
import PrivateRoute from "./components/PrivateRoute";
import {DashboardPage} from "./components/dashboard/DashboardPage";
import CssBaseline from "@material-ui/core/CssBaseline";
import {MainMenu} from "./components/mainmenu/MainMenu";
import {useDispatch} from "react-redux";
import {AUTH_CHECK, GET_APP_NAME} from "./ducks/AuthReducer";

export const App = () => {
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch({type:AUTH_CHECK})
        dispatch({type:GET_APP_NAME})
    })

    return (
    <div className="App">
      <CssBaseline/>
      <header className="App-header">
          <HashRouter>
            <Switch>
                <Route path={ROUTES_LOGIN} component={LoginPage} />
                <PrivateRoute path={ROUTES_MAIN_MENU} component={MainMenu} />
                <PrivateRoute path={ROUTES_DASHBOARD} component={DashboardPage} />
                <Redirect from="/" to={ROUTES_MAIN_MENU} />
            </Switch>
          </HashRouter>
      </header>
    </div>
    );
}
