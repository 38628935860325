
export const STAGE_REQUEST_TABLE = "STAGE_REQUEST_TABLE";
export const STAGE_REQUEST_TABLE_SUCCESS = "STAGE_REQUEST_TABLE_SUCCESS";
export const GROUP_REQUEST_TABLE = "GROUP_REQUEST_TABLE";
export const GROUP_REQUEST_TABLE_SUCCESS = "GROUP_REQUEST_TABLE_SUCCESS";
export const FACTOR_REQUEST_TABLE = "FACTOR_REQUEST_TABLE";
export const FACTOR_REQUEST_TABLE_SUCCESS = "FACTOR_REQUEST_TABLE_SUCCESS";
export const INDICATOR_REQUEST_TABLE = "INDICATOR_REQUEST_TABLE";
export const INDICATOR_REQUEST_TABLE_SUCCESS = "INDICATOR_REQUEST_TABLE_SUCCESS";

export const STAGE_REQUEST_CREATE = "STAGE_REQUEST_CREATE";
export const STAGE_REQUEST_CREATE_SUCCESS = "STAGE_REQUEST_CREATE_SUCCESS";
export const GROUP_REQUEST_CREATE = "GROUP_REQUEST_CREATE";
export const GROUP_REQUEST_CREATE_SUCCESS = "GROUP_REQUEST_CREATE_SUCCESS";
export const FACTOR_REQUEST_CREATE = "FACTOR_REQUEST_CREATE";
export const FACTOR_REQUEST_CREATE_SUCCESS = "FACTOR_REQUEST_CREATE_SUCCESS";
export const INDICATOR_REQUEST_CREATE = "INDICATOR_REQUEST_CREATE";
export const INDICATOR_REQUEST_CREATE_SUCCESS = "INDICATOR_REQUEST_CREATE_SUCCESS";

export const STAGE_REQUEST_DELETE = "STAGE_REQUEST_DELETE";
export const STAGE_REQUEST_DELETE_SUCCESS = "STAGE_REQUEST_DELETE_SUCCESS";
export const GROUP_REQUEST_DELETE = "GROUP_REQUEST_DELETE";
export const GROUP_REQUEST_DELETE_SUCCESS = "GROUP_REQUEST_DELETE_SUCCESS";
export const FACTOR_REQUEST_DELETE = "FACTOR_REQUEST_DELETE";
export const FACTOR_REQUEST_DELETE_SUCCESS = "FACTOR_REQUEST_DELETE_SUCCESS";
export const INDICATOR_REQUEST_DELETE = "INDICATOR_REQUEST_DELETE";
export const INDICATOR_REQUEST_DELETE_SUCCESS = "INDICATOR_REQUEST_DELETE_SUCCESS";

export const MODAL_DICT_CREATE_NEW_SHOW = "MODAL_DICT_CREATE_NEW_SHOW"
export const MODAL_DICT_CREATE_NEW_CLOSE = "MODAL_DICT_CREATE_NEW_CLOSE"
export const MODAL_DICT_DELETE_OPEN = "MODAL_DICT_DELETE_OPEN"
export const MODAL_DICT_DELETE_CLOSE = "MODAL_DICT_DELETE_CLOSE"
export const SET_DICT_ROWS_PER_PAGE = "SET_DICT_ROWS_PER_PAGE"
export const SET_DICT_PAGE_NUMBER = "SET_DICT_PAGE_NUMBER"

const initialState = {
    dictionary_list: [],
    selectedIds: [],
    pageNumber: 0,
    rowsPerPage: 10,
    showModalCreateNew: false,
    showModalDelete: false,
};

export const DictionaryTableReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case STAGE_REQUEST_TABLE_SUCCESS:
        case GROUP_REQUEST_TABLE_SUCCESS:
        case FACTOR_REQUEST_TABLE_SUCCESS:
        case INDICATOR_REQUEST_TABLE_SUCCESS: {
            return { ...state, dictionary_list: payload };
        }
        case MODAL_DICT_CREATE_NEW_SHOW: {
            return { ...state, showModalCreateNew: true}
        }
        case MODAL_DICT_CREATE_NEW_CLOSE: {
            return { ...state, showModalCreateNew: false}
        }
        case MODAL_DICT_DELETE_OPEN: {
            return { ...state, showModalDelete: true}
        }
        case MODAL_DICT_DELETE_CLOSE: {
            return { ...state, showModalDelete: false}
        }
        case SET_DICT_ROWS_PER_PAGE: {
            return { ...state, rowsPerPage: payload}
        }
        case SET_DICT_PAGE_NUMBER: {
            return { ...state, pageNumber: payload}
        }
        default:
            return state;
    }
};