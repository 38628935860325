import { takeLatest, put, delay, call} from 'redux-saga/effects'
import {GLOBAL_ERROR, GLOBAL_ERROR_CLEAR, GLOBAL_SUCCESS, GLOBAL_SUCCESS_CLEAR, GET_LOCALIZATION,
  SET_LOCALIZATION} from "../ducks/UserInterfaceReducer";
import {getErrorMessage, simpleRequest, URL_GET_LOCALIZATION} from '../service/RequestService'

export function* UISagaWatcher() {
    yield takeLatest(GLOBAL_ERROR, errorDelay)
    yield takeLatest(GLOBAL_SUCCESS, successDelay)
    yield takeLatest(GET_LOCALIZATION, getLocalization)
}

function* errorDelay(action){
    yield delay(10000)
    yield put({type: GLOBAL_ERROR_CLEAR})
}
function* successDelay(action){
    yield delay(10000)
    yield put({type: GLOBAL_SUCCESS_CLEAR})
}

function* getLocalization() {
  try {
    const response  = yield call(simpleRequest, URL_GET_LOCALIZATION ,'GET',null,null)
    yield put({ type: SET_LOCALIZATION, payload: response.data });
  } catch (error) {
      yield put({ type: GLOBAL_ERROR, payload: getErrorMessage(error.response.status) });
  }
}