import React from 'react';
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import {useStyles} from "../../jss/DashboardStyles";
import {
    DICT_FACTORS,
    DICT_GROUPS, DICT_INDICATORS, DICT_STAGES, FACTOR_MODEL,
    MAIN_SIDEBAR_WIDTH,
    SIDEBAR_MINIMAL_WIDTH,
    TOP_PANEL_HEIGHT
} from "../../const/UIConst";
import Alert from "@material-ui/lab/Alert";
import {MainSidebar} from "./MainSidebar";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
    CLOSE_MAIN_SIDEBAR,
    OPEN_MAIN_SIDEBAR,
} from "../../ducks/UserInterfaceReducer";
import {DictionaryTable} from "./DictionaryTable";
import {Route, Switch} from "react-router-dom";
import {FactorModelTable} from "./FactorModelTable";
import {
    ROUTES_MAIN_MENU,
    ROUTES_MAIN_MENU_DICTIONARY,
    ROUTES_MAIN_MENU_DICTIONARY_FACTOR,
    ROUTES_MAIN_MENU_DICTIONARY_GROUP,
    ROUTES_MAIN_MENU_DICTIONARY_INDICATOR, ROUTES_MAIN_MENU_DICTIONARY_STAGE,
    ROUTES_MAIN_MENU_FACTOR_MODEL_TABLE
} from "../../const/ConstRoutes";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import {Divider} from "@material-ui/core";
import {LanguagePicker} from "../LanguagePicker";

export const MainMenu = ({location}) => {
    const classes = useStyles();
    const dispatch = useDispatch()

    const mainSidebarOpened  = useSelector(store => store.user_interface.mainSidebarOpen)
    const activeMenuItem  = useSelector(store => store.user_interface.activeMenuItem)
    const error = useSelector(store => store.user_interface.error)  //all global error must throw GLOBAL_ERROR action
    const success = useSelector(store => store.user_interface.success)
    const { t } = useTranslation();

    function openMainSidebar() {
        if (mainSidebarOpened === true) {
            dispatch({ type: CLOSE_MAIN_SIDEBAR});
        } else {
            dispatch({ type: OPEN_MAIN_SIDEBAR});
        }
    }

    const getActiveMenuName = () => {
        switch(activeMenuItem) {
            case DICT_GROUPS: {
                return t("groupsLabel")
            }
            case DICT_FACTORS: {
                return t("factorsLabel")
            }
            case DICT_INDICATORS: {
                return t("indicatorsLabel")
            }
            case DICT_STAGES: {
                return t("stagesLabel")
            }
            case FACTOR_MODEL: {
                return t("fmLabel")
            }
            default:
                return ""
        }
    }


    return(
        <Grid container component="main" className={classes.root}>

                <MainSidebar/>
                <Grid container component={Paper} style={{whiteSpace: "nowrap"}}>

                    <Grid item style={{width: mainSidebarOpened ? MAIN_SIDEBAR_WIDTH : SIDEBAR_MINIMAL_WIDTH, height:"100vh"}}>
                        <IconButton onClick={openMainSidebar}>
                            <ChevronRightIcon />
                        </IconButton>
                    </Grid>

                    <Grid  item style={{flex:"auto"}}>
                        <div style={{height:TOP_PANEL_HEIGHT, width:"100%"}}>
                            <div style={{
                                position:"absolute",
                                height:TOP_PANEL_HEIGHT,
                                left:"100%",
                                transform:"translate(-150%,0%)",
                            }}>
                                <LanguagePicker/>
                            </div>
                            <Grid container item xs={12} justify={"center"} alignItems={"center"} style={{height: TOP_PANEL_HEIGHT}}>
                                {error && <Alert severity='error' style={{position:"fixed",left:"50%",transform:"translate(-50%,0%)"}}> {error} </Alert>}
                                {success && <Alert severity='success' style={{position:"fixed",left:"50%",transform:"translate(-50%,0%)"}}> {error} </Alert>}
                                {!error && !success && location.pathname!==ROUTES_MAIN_MENU && <Typography variant={"h5"}  style={{color:"black",}}> {getActiveMenuName()} </Typography> }
                            </Grid>
                        </div>
                        <Divider/>
                        <Switch>
                            <Route path={ROUTES_MAIN_MENU_DICTIONARY_GROUP} component={DictionaryTable}/>
                            <Route path={ROUTES_MAIN_MENU_DICTIONARY_FACTOR} component={DictionaryTable}/>
                            <Route path={ROUTES_MAIN_MENU_DICTIONARY_INDICATOR} component={DictionaryTable}/>
                            <Route path={ROUTES_MAIN_MENU_DICTIONARY_STAGE} component={DictionaryTable}/>
                            <Route path={ROUTES_MAIN_MENU_FACTOR_MODEL_TABLE} component={FactorModelTable}/>
                        </Switch>
                    </Grid>

                </Grid>


        </Grid>
    )
}