const regNumeric = new RegExp('^\\d+$');
const regFloatLessOne = new RegExp('^[+]?([0]*[.])?[0-9]');

// from 0 to 100
export const validatePercent = (value) => {
    if (isNaN(value)) return false
    if (!regNumeric.test(value)) return false
    const parseValue = parseFloat(value)
    return !(parseValue < 0 || parseValue > 100);
}

// from 0 to 1
export const validateFloatLessOne = (value) => {
    if (isNaN(value)) return false
    if (value==="") return true
    if (!regFloatLessOne.test(value)) return false
    const parseValue = parseFloat(value)
    return !(parseValue < 0 || parseValue > 1 || value.length>3);
}

export const validateNumeric = (value) => {
    if (isNaN(value)) return false
    return regNumeric.test(value);

}