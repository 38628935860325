import React, {useEffect, useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import {useStyles} from "../../../jss/DashboardStyles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useDispatch, useSelector} from "react-redux";
import {getStoreWeightByCoordinates} from "../../../service/FactorModelService";
import {SET_FACTOR_MODEL_POINT_STOP_FACTOR, SET_FACTOR_MODEL_POINT_WEIGHT} from "../../../ducks/FactorModelReducer";
import {validateFloatLessOne, validatePercent} from "../../../service/ValidationService";

export const InputCell = ({rowSpan, align, indicator, coordinates}) => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const weight = useSelector(store => getStoreWeightByCoordinates(store,coordinates))
    const [value,setValue] = useState(0)
    const [checkbox,setCheckbox] = useState(false)

    useEffect(() => {
        if (weight) {
            setValue(weight.weight)
            setCheckbox(weight.isStopFactor)
        }
    },[weight])

    const validate = (e) => {
        const value = +e.target.value
        if (indicator ? validateFloatLessOne(e.target.value) : validatePercent(value)){
            dispatch({ type: SET_FACTOR_MODEL_POINT_WEIGHT, payload: {value: indicator ? e.target.value : value != 0 ? value : "", coordinates: coordinates}})
        }
    }
    const onBlurCell = (e) => {//если в ячейке нет значений - проставим ноль, при снятии фокуса
        setValue(e.target.value != "" ? e.target.value : 0)
    }
    const toggleStopFactor = (e) => {
        dispatch({ type: SET_FACTOR_MODEL_POINT_STOP_FACTOR, payload: {value: e.target.checked, coordinates: coordinates}})
    }
    return (
        <TableCell className={classes.tableBorderPrimary}
                   rowSpan={rowSpan}
                   align={align ? align : "left"}
                   style={{
                       backgroundColor: !indicator ? "rgba(244, 244, 244, 1)" : checkbox ? "#f0808099" : "white",
                       maxWidth: "30px",
                       minWidth: "135px"
                   }}
                   size={"small"}
                   padding={"default"}
        >
            <TextField className={classes.primaryBorderOutline}
                       value={value}
                       onFocus={() => value != 0 ? value : setValue("")}
                       onChange={(e) => validate(e)}
                       onBlur={(e) =>onBlurCell(e)}
                       size={"small"}
                       variant={"outlined"}
                       InputProps={{
                           endAdornment: !indicator && <InputAdornment position="end">%</InputAdornment>,
                       }}
                       style={{
                           display: "table-cell",

                       }}
            />
            {indicator &&
            <Checkbox
                className={classes.backgroundCheckBox}
                checked={checkbox}
                onChange={(e) => toggleStopFactor(e)}
                style={{
                    display: "table-cell",
                    transform: "scale(1)"
                }}
            />
            }

        </TableCell>
    );
}

