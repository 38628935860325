export const REQUEST_FM = "REQUEST_FM";
export const REQUEST_FM_SUCCESS = "REQUEST_FM_SUCCESS";
export const REQUEST_FM_ERROR = "REQUEST_FM_ERROR";
export const REQUEST_FM_TABLE_PAGE = "REQUEST_FM_TABLE_PAGE";
export const REQUEST_FM_TABLE_PAGE_SUCCESS = "REQUEST_FM_TABLE_PAGE_SUCCESS";
export const REQUEST_FM_TABLE_PAGE_ERROR = "REQUEST_FM_TABLE_PAGE_ERROR";
export const REQUEST_FM_COPY = "REQUEST_FM_COPY";
export const REQUEST_FM_COPY_SUCCESS = "REQUEST_FM_COPY_SUCCESS";
export const REQUEST_FM_COPY_ERROR = "REQUEST_FM_COPY_ERROR";
export const REQUEST_FM_DELETE = "REQUEST_FM_DELETE";
export const REQUEST_FM_DELETE_SUCCESS = "REQUEST_FM_DELETE_SUCCESS";
export const REQUEST_FM_DELETE_ERROR = "REQUEST_FM_DELETE_ERROR";
export const MODAL_COPY_SHOW = "MODAL_COPY_SHOW"
export const MODAL_COPY_CLOSE = "MODAL_COPY_CLOSE"
export const MODAL_DELETE_SHOW = "MODAL_DELETE_SHOW"
export const MODAL_DELETE_CLOSE = "MODAL_DELETE_CLOSE"
export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE"
export const SET_PAGE_NUMBER = "SET_PAGE_NUMBER"

const initialState = {
    factorModelResult: [],
    selectedIds: [],
    pageNumber: 0,
    rowsPerPage: 10,
    error: null,
    showModalCopyFrom: false,
    showModalDelete: false,

}

export const FactorModelTableReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case REQUEST_FM_TABLE_PAGE_SUCCESS: {
            return { ...state, factorModelResult: payload}
        }
        case REQUEST_FM_TABLE_PAGE_ERROR: {
            return { ...state, error: payload}
        }
        case MODAL_COPY_SHOW: {
            return { ...state, showModalCopyFrom: true}
        }
        case MODAL_COPY_CLOSE: {
            return { ...state, showModalCopyFrom: false}
        }
        case MODAL_DELETE_SHOW: {
            return { ...state, showModalDelete: true}
        }
        case MODAL_DELETE_CLOSE: {
            return { ...state, showModalDelete: false}
        }
        case SET_ROWS_PER_PAGE: {
            return { ...state, rowsPerPage: payload}
        }
        case SET_PAGE_NUMBER: {
            return { ...state, pageNumber: payload}
        }
        default:
            return state;
    }
}

