import React from "react";
import {useDispatch} from "react-redux";
import clsx from "clsx";
import {Droppable} from "react-beautiful-dnd";
import CloseIcon from '@material-ui/icons/Close';
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import {useStyles} from "../../../jss/DashboardStyles";
import {
    FACTOR_DROPPABLE_ID,
    INDICATOR_DROPPABLE_ID,
    STAGE_DROPPABLE_ID
} from "../../../const/UIConst";
import {
    FACTOR_REMOVE,
    GROUP_REMOVE,
    STAGE_REMOVE
} from "../../../ducks/FactorModelReducer";

const getIdFromDropType = (dropType,item) => {
    switch(dropType){
        case STAGE_DROPPABLE_ID: {
            return item.id
        }
        case FACTOR_DROPPABLE_ID: {
            return item.groupId
        }
        case INDICATOR_DROPPABLE_ID: {
            return item.factorId
        }
        default:
            return []
    }
}

export const DroppableCell = ({item, dropType, rowSpan, align, header, caption, parentProps}) => {

    const classes = useStyles()
    const dispatch = useDispatch()

    const removeCell = (e) => {
        switch(dropType){
            case STAGE_DROPPABLE_ID: {
                dispatch({type: STAGE_REMOVE, payload: {id: getIdFromDropType(dropType,item)} })
                break
            }
            case FACTOR_DROPPABLE_ID: {
                dispatch({type: GROUP_REMOVE, payload: {id: getIdFromDropType(dropType,item)} })
                break
            }
            case INDICATOR_DROPPABLE_ID: {
                dispatch({type: FACTOR_REMOVE, payload: {id: getIdFromDropType(dropType,item), groupId: parentProps.groupId} })
                break
            }
            default:
                return []
        }
    }

    return(
        <Droppable droppableId={item ? JSON.stringify(item) : dropType} type={dropType} >
            {(provided, snapshot) => {
                return(
                    <TableCell
                        className={
                        clsx({
                            [classes.tableHeader]: header,
                            [classes.tableBorderPrimary]: true,
                            [classes.tableCellPrimary]: true,})}
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        rowSpan={rowSpan}
                        align={align ? align : "left"}
                        style={{
                            background: snapshot.isDraggingOver ? '#FFA289' : header? "#ff5629" :'white',
                            verticalAlign: "top",
                        }}
                    >
                        <div>
                            {item && item.name}
                            {!item && caption}
                            {item &&
                            <IconButton className={clsx({
                                [classes.primaryColor]: !header,
                                [classes.colorWhite]: header
                            })}
                                        size={"small"}
                                        onClick={removeCell}
                                        style={{float: "right"}}>
                                <CloseIcon fontSize={"default"}/>
                            </IconButton>
                            }
                        </div>
                        <div style={{maxWidth:"0px",maxHeight:"0px"}}>
                            {provided.placeholder}
                        </div>
                    </TableCell>
                )
            }}
        </Droppable>
    )
}