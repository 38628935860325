const getStageWeights = (factorModel) => {
    let stageWeights = []
    for (let stage of factorModel.stages) {
        stageWeights.push({
            stageId: stage.id,
            weight: 0,
            isStopFactor: false
        })
    }
    return stageWeights
}

export const setWeightByCoordinates = (state,payload) => {
    const coordinates = payload.coordinates
    if (coordinates.groupId && coordinates.factorId && coordinates.indicatorId){
        return {
            ...state, factorModel: {
                ...state.factorModel, groups:
                    state.factorModel.groups.map(group =>
                        group.groupId === coordinates.groupId ? {
                            ...group, factors: group.factors.map(factor =>
                                factor.factorId === coordinates.factorId ? {
                                    ...factor, indicators: factor.indicators.map(indicator =>
                                        indicator.indicatorId === coordinates.indicatorId ? {
                                            ...indicator, stageWeights: indicator.stageWeights.map(stageWeight =>
                                                stageWeight.stageId === coordinates.stageId ? {
                                                    ...stageWeight,
                                                    weight: payload.value
                                                } : stageWeight)
                                        } : indicator)
                                } : factor)
                        } : group
                    )
            }
        };
    }
    if (coordinates.groupId && coordinates.factorId && coordinates.indicatorId===null){
        return {
            ...state, factorModel: {
                ...state.factorModel, groups:
                    state.factorModel.groups.map(group =>
                        group.groupId === coordinates.groupId ? {
                            ...group, factors: group.factors.map(factor =>
                                factor.factorId === coordinates.factorId ? {
                                    ...factor, stageWeights: factor.stageWeights.map(stageWeight =>
                                        stageWeight.stageId === coordinates.stageId ? {
                                            ...stageWeight,
                                            weight: payload.value
                                        } : stageWeight)
                                } : factor)
                        } : group
                    )
            }
        };
    }
    if (coordinates.groupId && coordinates.factorId===null && coordinates.indicatorId===null){
        return {
            ...state, factorModel: {
                ...state.factorModel, groups:
                    state.factorModel.groups.map(group =>
                        group.groupId === coordinates.groupId ? {
                            ...group, stageWeights: group.stageWeights.map(stageWeight =>
                                stageWeight.stageId === coordinates.stageId ? {
                                    ...stageWeight,
                                    weight: payload.value
                                } : stageWeight)
                        } : group
                    )
            }
        };
    }
    return state
}

export const setStopFactorByCoordinates = (state,payload) => {
    const coordinates = payload.coordinates
    if (coordinates.groupId && coordinates.factorId && coordinates.indicatorId){
        return {
            ...state, factorModel: {
                ...state.factorModel, groups:
                state.factorModel.groups.map(group =>
                    group.groupId === coordinates.groupId ? {
                        ...group, factors: group.factors.map(factor =>
                            factor.factorId === coordinates.factorId ? {
                                ...factor, indicators: factor.indicators.map(indicator =>
                                    indicator.indicatorId === coordinates.indicatorId ? {
                                        ...indicator, stageWeights: indicator.stageWeights.map(stageWeight =>
                                            stageWeight.stageId === coordinates.stageId ? {
                                                ...stageWeight,
                                                isStopFactor: payload.value
                                            } : stageWeight)
                                    } : indicator)
                            } : factor)
                    } : group
                )
            }
        };
    }
    return state
}

export const getStoreWeightByCoordinates = (store,coordinates) => {
    const fm  = store.desktop.factorModel
    if (coordinates.groupId && coordinates.factorId && coordinates.indicatorId){
        const group = fm.groups.find(g => g.groupId === coordinates.groupId)
        const factor = group.factors.find(f => f.factorId === coordinates.factorId)
        const indicator = factor.indicators.find(i => i.indicatorId === coordinates.indicatorId)
        return indicator.stageWeights.find(s=>s.stageId===coordinates.stageId)
    }
    if (coordinates.groupId && coordinates.factorId && coordinates.indicatorId===null){
        const group = fm.groups.find(g => g.groupId === coordinates.groupId)
        const factor = group.factors.find(f => f.factorId === coordinates.factorId)
        return factor.stageWeights.find(s=>s.stageId===coordinates.stageId)
    }
    if (coordinates.groupId && coordinates.factorId===null && coordinates.indicatorId===null){
        const group = fm.groups.find(g => g.groupId === coordinates.groupId)
        return group.stageWeights.find(s=>s.stageId===coordinates.stageId)
    }
    return null
}

export const getNewFactorModelAfterAddGroup = (dragItem, factorModel) => {
    return {
        ...factorModel, groups: factorModel.groups.concat([{
            id: null,
            groupId: dragItem.id,
            name: dragItem.name,
            stageWeights: getStageWeights(factorModel),
            factors: []
        }])
    }
}

export const getNewFactorModelAfterAddFactor = (dragItem, factorModel) => {
    let newFactorModel = {...factorModel}
    for (let group of newFactorModel.groups) {
        if (group.groupId === dragItem.target.groupId && group.name === dragItem.target.name) {
            group.factors.push({
                id: null,
                factorId: dragItem.id,
                name: dragItem.name,
                stageWeights: getStageWeights(factorModel),
                indicators: []
            })
            return newFactorModel
        }
    }
    return newFactorModel
}

export const getNewFactorModelAfterAddIndicator = (dragItem, factorModel) => {
    let newFactorModel = {...factorModel}
    for (let group of newFactorModel.groups) {
        for (let factor of group.factors) {
            if (factor.factorId === dragItem.target.factorId && factor.name === dragItem.target.name) {
                factor.indicators.push({
                    id: null,
                    indicatorId: dragItem.id,
                    name: dragItem.name,
                    stageWeights: getStageWeights(factorModel),
                })
                return newFactorModel
            }
        }
    }
    return newFactorModel
}

const getStageWeightsAfterDeleteStage = (stageId, stageWeights) => {
    return stageWeights.filter(sw => sw.stageId !== stageId)
}

export const getNewFactorModelAfterDeleteStage = (dragItem, factorModel) => {
    let newFactorModel = {...factorModel}
    newFactorModel.stages = newFactorModel.stages.filter(st => st.id !== dragItem.id)
    for (let group of newFactorModel.groups) {
        group.stageWeights = getStageWeightsAfterDeleteStage(dragItem.id, group.stageWeights)
        for (let factor of group.factors) {
            factor.stageWeights = getStageWeightsAfterDeleteStage(dragItem.id, factor.stageWeights)
            for (let indicator of factor.indicators) {
                indicator.stageWeights = getStageWeightsAfterDeleteStage(dragItem.id, indicator.stageWeights)
            }
        }
    }
    return newFactorModel
}

export const getNewFactorModelAfterDeleteGroup = (dragItem, factorModel) => {
    let newFactorModel = {...factorModel}
    newFactorModel.groups = newFactorModel.groups.filter(gr => gr.groupId !== dragItem.id)
    return newFactorModel
}

export const getNewFactorModelAfterDeleteFactor = (dragItem, factorModel) => {
    let newFactorModel = {...factorModel}
    for (let group of newFactorModel.groups) {
        if (group.groupId === dragItem.groupId) {
            group.factors = group.factors.filter(fc => fc.factorId !== dragItem.id)
            break
        }
    }
    return newFactorModel
}

export const getNewFactorModelAfterDeleteIndicator = (dragItem, factorModel) => {
    let newFactorModel = {...factorModel}
    for (let group of newFactorModel.groups) {
        if (group.groupId === dragItem.groupId) {
            for (let factor of group.factors) {
                if (factor.factorId === dragItem.factorId) {
                    factor.indicators = factor.indicators.filter(ind => ind.indicatorId !== dragItem.id)
                    break
                }
            }
        }
    }
    return newFactorModel
}

export const getNewFactorModelAfterAddNewStage = (dragItem, factorModel) => {
    let newFactorModel = {...factorModel}
    newFactorModel.stages = newFactorModel.stages.concat([{
        id: dragItem.id,
        name: dragItem.name,
    }])
    for (let group of newFactorModel.groups) {
        group.stageWeights = group.stageWeights.concat([{
            stageId: dragItem.id,
            weight: 0,
            isStopFactor: false
        }])

        for (let factor of group.factors) {
            factor.stageWeights = factor.stageWeights.concat([{
                stageId: dragItem.id,
                weight: 0,
                isStopFactor: false
            }])

            for (let indicator of factor.indicators) {
                indicator.stageWeights = indicator.stageWeights.concat([{
                    stageId: dragItem.id,
                    weight: 0,
                    isStopFactor: false
                }])
            }
        }
    }
    return newFactorModel
}


export const getFactorModelStageIds = (factorModel) => {
    const stageIds = []
    for (let stage of factorModel.stages) {
        stageIds.push(stage.id)
    }
    return stageIds
}

export const getFactorModelGroupIds = (factorModel) => {
    const groupIds = []
    for (let group of factorModel.groups) {
        groupIds.push(group.groupId)
    }
    return groupIds
}

export const getFactorModelFactorIds = (factorModel) => {
    const factorIds = []
    for (let group of factorModel.groups) {
        for (let factor of group.factors) {
            factorIds.push(factor.factorId)
        }
    }
    return factorIds
}

export const getFactorModelIndicatorsIds = (factorModel) => {
    const indicatorIds = []
    for (let group of factorModel.groups) {
        for (let factor of group.factors) {
            for (let indicator of factor.indicators) {
                indicatorIds.push(indicator.indicatorId)
            }
        }
    }
    return indicatorIds
}