import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {FACTOR_REQUEST, GROUP_REQUEST, INDICATOR_REQUEST, STAGE_REQUEST} from "../../ducks/PaletteReducer";
import {CardContent, Divider, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import {Draggable, Droppable} from "react-beautiful-dnd";
import {
    FACTOR_DROPPABLE_ID,
    GROUP_DROPPABLE_ID, INDICATOR_DROPPABLE_ID, SIDEBAR_LIST_ITEM_WIDTH,
    PALETTE_DICT_LIST_HEIGHT, STAGE_DROPPABLE_ID
} from "../../const/UIConst";
import {useStyles} from "../../jss/DashboardStyles";
import {
    getFactorModelFactorIds,
    getFactorModelGroupIds, getFactorModelIndicatorsIds,
    getFactorModelStageIds
} from "../../service/FactorModelService";
import Tooltip from "@material-ui/core/Tooltip";
import {useTranslation} from "react-i18next";

export const PaletteList = (props) => {
    const classes = useStyles()
    const {request_action} = props
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const factorModel = useSelector(store => store.desktop.factorModel)
    const itemsListStore = useSelector(state => {switch(request_action){
        case STAGE_REQUEST: {
            return state.palette.stage_list
        }
        case GROUP_REQUEST: {
            return state.palette.group_list
        }
        case FACTOR_REQUEST: {
            return state.palette.factor_list
        }
        case INDICATOR_REQUEST: {
            return state.palette.indicator_list
        }
        default:
            return []
    }} )

    const getTitle = (request_action) => {
        switch(request_action){
            case STAGE_REQUEST: {
                return t("stagesLabel")
            }
            case GROUP_REQUEST: {
                return t("groupsLabel")
            }
            case FACTOR_REQUEST: {
                return t("factorsLabel")
            }
            case INDICATOR_REQUEST: {
                return t("indicatorsLabel")
            }
            default:
                return []
        }
    }

    const [itemList,setItemList] = useState([])

    useEffect( () => {
        dispatch({type:request_action})
    },[request_action,dispatch])

    const filterItemsByModel = (items) => {
        switch(request_action){
            case STAGE_REQUEST: {
                return items = items.filter(item => !getFactorModelStageIds(factorModel).includes(item.id))
            }
            case GROUP_REQUEST: {
                return items = items.filter(item => !getFactorModelGroupIds(factorModel).includes(item.id))
            }
            case FACTOR_REQUEST: {
                return items = items.filter(item => !getFactorModelFactorIds(factorModel).includes(item.id))
            }
            case INDICATOR_REQUEST: {
                return items = items.filter(item => !getFactorModelIndicatorsIds(factorModel).includes(item.id))
            }
            default:
                return []
        }
    }

    const [searchValue,setSearchValue] = useState("")
    useEffect(() => {
        setItemList(filterItemsByModel(itemsListStore))
        if (searchValue!=''){//нужно, что бы в момент dragNdrop не сбрасывались результаты поиска
            setItemList(filterItemsByModel(itemsListStore).filter(function (e) {
                return e.name.toLowerCase().includes(searchValue.toLowerCase())
            }))
        }
    },[factorModel,itemsListStore])


    const textSearch = (event) => {
        if (!event.target.value || event.target.value==='')
            setItemList(filterItemsByModel(itemsListStore))
        else{
            setSearchValue(event.target.value);
            setItemList(filterItemsByModel(itemsListStore).filter(function (e) {
                return e.name.toLowerCase().includes(event.target.value.toLowerCase())
            }))
        }

    }

    return (
        <>
            <Card style={{margin:"8px"}}
                  variant="outlined"
            >
                <TextField
                    label={getTitle(request_action)}
                    placeholder={t("searchText")}
                    variant={"outlined"}
                    size={"small"}
                    style={{margin:"8px"}}
                    onChange={textSearch}
                    className={classes.primaryBorderOutline}
                />
                <Divider/>
                <CardContent>
                    <Droppable droppableId={request_action}
                               type={getDroppableIdByAction(request_action)}
                               style={{
                                   maxWidth:"200px",
                                   maxHeight:PALETTE_DICT_LIST_HEIGHT,
                               }}>
                        {(provided, snapshot) => {
                            return(
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{maxHeight:PALETTE_DICT_LIST_HEIGHT, overflow: "auto"}}
                                >
                                    {
                                        itemList.map((item, index) => {
                                            return (
                                                    <Draggable key={item.id} draggableId={JSON.stringify(item)} index={index}>
                                                        {(provided, snapshot) => {
                                                            return (
                                                                <Tooltip title={item.name} interactive>
                                                                    <Typography ref={provided.innerRef}
                                                                         {...provided.draggableProps}
                                                                         {...provided.dragHandleProps}
                                                                         {...provided.item={item}}

                                                                        align={"left"}
                                                                         style={{
                                                                             marginLeft:"15px",
                                                                             userSelect: "none",
                                                                             backgroundColor: snapshot.isDragging ? '#FF805F' : "white",
                                                                             ...provided.draggableProps.style,
                                                                             maxWidth: SIDEBAR_LIST_ITEM_WIDTH
                                                                         }}
                                                                         noWrap={true}
                                                                    >
                                                                        {item.name}
                                                                    </Typography>
                                                                </Tooltip>
                                                            )
                                                        }}
                                                    </Draggable>
                                            )
                                        })
                                    }
                                    {provided.placeholder}
                                </div>
                            )
                        }}
                    </Droppable>
                </CardContent>
            </Card>
        </>
    )
}

const getDroppableIdByAction = (action) => {
    switch (action){
        case STAGE_REQUEST: {
            return STAGE_DROPPABLE_ID
        }
        case GROUP_REQUEST: {
            return GROUP_DROPPABLE_ID
        }
        case FACTOR_REQUEST: {
            return FACTOR_DROPPABLE_ID
        }
        case INDICATOR_REQUEST: {
            return INDICATOR_DROPPABLE_ID
        }
        default:
            return ""
    }
}