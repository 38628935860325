import { takeLatest, put, call, select} from 'redux-saga/effects'
import {
    getErrorMessage,
    simpleRequest,
    URL_DELETE_FACTOR_DELETE,
    URL_DELETE_GROUP_DELETE,
    URL_DELETE_INDICATOR_DELETE,
    URL_DELETE_STAGE_DELETE,
    URL_GET_FACTOR_TABLE,
    URL_GET_GROUP_TABLE,
    URL_GET_INDICATOR_TABLE,
    URL_GET_STAGE_TABLE,
    URL_PUT_FACTOR_CREATE,
    URL_PUT_GROUP_CREATE,
    URL_PUT_INDICATOR_CREATE,
    URL_PUT_STAGE_CREATE,
} from "../service/RequestService";
import {GLOBAL_ERROR} from "../ducks/UserInterfaceReducer";
import {
    FACTOR_REQUEST_CREATE, FACTOR_REQUEST_CREATE_SUCCESS,
    FACTOR_REQUEST_DELETE, FACTOR_REQUEST_DELETE_SUCCESS,
    FACTOR_REQUEST_TABLE,
    FACTOR_REQUEST_TABLE_SUCCESS,
    GROUP_REQUEST_CREATE, GROUP_REQUEST_CREATE_SUCCESS,
    GROUP_REQUEST_DELETE, GROUP_REQUEST_DELETE_SUCCESS,
    GROUP_REQUEST_TABLE,
    GROUP_REQUEST_TABLE_SUCCESS,
    INDICATOR_REQUEST_CREATE, INDICATOR_REQUEST_CREATE_SUCCESS,
    INDICATOR_REQUEST_DELETE, INDICATOR_REQUEST_DELETE_SUCCESS,
    INDICATOR_REQUEST_TABLE,
    INDICATOR_REQUEST_TABLE_SUCCESS,
    STAGE_REQUEST_CREATE,
    STAGE_REQUEST_CREATE_SUCCESS,
    STAGE_REQUEST_DELETE, STAGE_REQUEST_DELETE_SUCCESS,
    STAGE_REQUEST_TABLE,
    STAGE_REQUEST_TABLE_SUCCESS
} from "../ducks/DictionaryTableReducer";
import i18next from "i18next";

export function* DictionarySagaWatcher() {
    yield takeLatest(STAGE_REQUEST_TABLE, getRequestTable)
    yield takeLatest(GROUP_REQUEST_TABLE, getRequestTable)
    yield takeLatest(FACTOR_REQUEST_TABLE, getRequestTable)
    yield takeLatest(INDICATOR_REQUEST_TABLE, getRequestTable)

    yield takeLatest(STAGE_REQUEST_CREATE, getRequestCreate)
    yield takeLatest(GROUP_REQUEST_CREATE, getRequestCreate)
    yield takeLatest(FACTOR_REQUEST_CREATE, getRequestCreate)
    yield takeLatest(INDICATOR_REQUEST_CREATE, getRequestCreate)

    yield takeLatest(STAGE_REQUEST_DELETE, getRequestDelete)
    yield takeLatest(GROUP_REQUEST_DELETE, getRequestDelete)
    yield takeLatest(FACTOR_REQUEST_DELETE, getRequestDelete)
    yield takeLatest(INDICATOR_REQUEST_DELETE, getRequestDelete)
}

function* getRequestTable(action){
    try {
        const  response  = yield call(simpleRequest,getRequestUrlByAction(action) ,'GET',action.payload,null);
        yield put({ type: getSuccessActionByAction(action), payload: response.data });
    } catch (error) {
        yield put({ type: GLOBAL_ERROR, payload: getErrorMessage(error.response.status) });
    }
}

function* getRequestCreate(action){
    try {
        const  response  = yield call(simpleRequest, getRequestUrlByAction(action),'PUT',null,action.payload);
        yield put({ type: getSuccessActionByAction(action), payload: response.data });
        const rowsPerPage = yield select(store => store.dictionaryTable.rowsPerPage)
        const pageNumber = yield select(store => store.dictionaryTable.pageNumber)
        yield put({type: getRequestTableActionByAction(action), payload: {page: pageNumber, size: rowsPerPage}})
    } catch (error) {
        yield put({ type: GLOBAL_ERROR, payload: getErrorMessage(error.response.status) });
    }
}

function* getRequestDelete(action){
    try {
        const  response  = yield call(simpleRequest, getRequestUrlByAction(action),'DELETE',action.payload,null);
        yield put({ type: getSuccessActionByAction(action), payload: response.data });
        const rowsPerPage = yield select(store => store.dictionaryTable.rowsPerPage)
        const pageNumber = yield select(store => store.dictionaryTable.pageNumber)
        yield put({type: getRequestTableActionByAction(action), payload: {page: pageNumber, size: rowsPerPage}})
    } catch (error) {
        yield put({ type: GLOBAL_ERROR, payload: i18next.t("errorAlreadyInUse") });
    }
}

function getRequestUrlByAction(action){
    switch (action.type){
        case STAGE_REQUEST_TABLE: { return URL_GET_STAGE_TABLE }
        case GROUP_REQUEST_TABLE: { return URL_GET_GROUP_TABLE }
        case FACTOR_REQUEST_TABLE: { return URL_GET_FACTOR_TABLE }
        case INDICATOR_REQUEST_TABLE: { return URL_GET_INDICATOR_TABLE }
        case STAGE_REQUEST_CREATE: { return URL_PUT_STAGE_CREATE }
        case GROUP_REQUEST_CREATE: { return URL_PUT_GROUP_CREATE }
        case FACTOR_REQUEST_CREATE: { return URL_PUT_FACTOR_CREATE }
        case INDICATOR_REQUEST_CREATE: { return URL_PUT_INDICATOR_CREATE }
        case STAGE_REQUEST_DELETE: { return URL_DELETE_STAGE_DELETE }
        case GROUP_REQUEST_DELETE: { return URL_DELETE_GROUP_DELETE }
        case FACTOR_REQUEST_DELETE: { return URL_DELETE_FACTOR_DELETE }
        case INDICATOR_REQUEST_DELETE: { return URL_DELETE_INDICATOR_DELETE }
        default:
            return ""
    }
}

function getSuccessActionByAction(action){
    switch (action.type){
        case STAGE_REQUEST_TABLE: { return STAGE_REQUEST_TABLE_SUCCESS }
        case GROUP_REQUEST_TABLE: { return GROUP_REQUEST_TABLE_SUCCESS }
        case FACTOR_REQUEST_TABLE: { return FACTOR_REQUEST_TABLE_SUCCESS }
        case INDICATOR_REQUEST_TABLE: { return INDICATOR_REQUEST_TABLE_SUCCESS }
        case STAGE_REQUEST_CREATE: { return STAGE_REQUEST_CREATE_SUCCESS }
        case GROUP_REQUEST_CREATE: { return GROUP_REQUEST_CREATE_SUCCESS }
        case FACTOR_REQUEST_CREATE: { return FACTOR_REQUEST_CREATE_SUCCESS }
        case INDICATOR_REQUEST_CREATE: { return INDICATOR_REQUEST_CREATE_SUCCESS }
        case STAGE_REQUEST_DELETE: { return STAGE_REQUEST_DELETE_SUCCESS }
        case GROUP_REQUEST_DELETE: { return GROUP_REQUEST_DELETE_SUCCESS }
        case FACTOR_REQUEST_DELETE: { return FACTOR_REQUEST_DELETE_SUCCESS }
        case INDICATOR_REQUEST_DELETE: { return INDICATOR_REQUEST_DELETE_SUCCESS }
        default:
            return ""
    }
}

function getRequestTableActionByAction(action){
    switch (action.type){
        case STAGE_REQUEST_CREATE: { return STAGE_REQUEST_TABLE }
        case GROUP_REQUEST_CREATE: { return GROUP_REQUEST_TABLE }
        case FACTOR_REQUEST_CREATE: { return FACTOR_REQUEST_TABLE }
        case INDICATOR_REQUEST_CREATE: { return INDICATOR_REQUEST_TABLE }
        case STAGE_REQUEST_DELETE: { return STAGE_REQUEST_TABLE }
        case GROUP_REQUEST_DELETE: { return GROUP_REQUEST_TABLE }
        case FACTOR_REQUEST_DELETE: { return FACTOR_REQUEST_TABLE }
        case INDICATOR_REQUEST_DELETE: { return INDICATOR_REQUEST_TABLE }
        default:
            return ""
    }
}