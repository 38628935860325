import {
    getErrorMessage,
    simpleRequest,
    URL_GET_ACCELERATOR_PROGRAMS,
    URL_GET_ACCELERATORS,
    URL_POST_CHECK_INDICATORS_IN_TEMPLATES,
    URL_POST_CREATE_FACTOR_MODEL
} from "../service/RequestService";
import {
    ACCELERATOR_PROGRAMS_REQUEST,
    ACCELERATOR_PROGRAMS_REQUEST_FAILED,
    ACCELERATOR_PROGRAMS_REQUEST_SUCCESS,
    ACCELERATORS_REQUEST,
    ACCELERATORS_REQUEST_FAILED,
    ACCELERATORS_REQUEST_SUCCESS,
    CHECK_INDICATORS_IN_TEMPLATES_REQUEST,
    CHECK_INDICATORS_IN_TEMPLATES_REQUEST_FAILED,
    CHECK_INDICATORS_IN_TEMPLATES_REQUEST_SUCCESS,
    FACTOR_MODEL_CREATE_REQUEST,
    FACTOR_MODEL_CREATE_REQUEST_FAILED,
    FACTOR_MODEL_CREATE_REQUEST_SUCCESS
} from "../ducks/SidebarSettingsReducer";
import {call, put, takeEvery, takeLatest} from "redux-saga/effects";
import {GLOBAL_ERROR, GLOBAL_SUCCESS} from "../ducks/UserInterfaceReducer";
import {SET_FACTOR_MODEL} from "../ducks/FactorModelReducer";

export function* SidebarSettingsSagaWatcher() {
    yield takeEvery(CHECK_INDICATORS_IN_TEMPLATES_REQUEST, getCheckIndicatorsInTemplatesRequest)
    yield takeLatest(ACCELERATORS_REQUEST, getAcceleratorsRequest)
    yield takeEvery(ACCELERATOR_PROGRAMS_REQUEST, getAcceleratorProgramsRequest)
    yield takeEvery(FACTOR_MODEL_CREATE_REQUEST, postFactorModelCreateRequest)
}

function* getCheckIndicatorsInTemplatesRequest(action) {
    try {
        const response = yield call(simpleRequest, getRequestUrlByAction(action), 'POST', null, action.payload);
        yield put({type: getSuccessActionByAction(action), payload: response.data});
        if (response.data.length === 0) {
            yield put({type: GLOBAL_SUCCESS, payload: "Indicators in the model are correct!"})
        }
    } catch (error) {
        let message = getErrorMessage(error.response.status);
        yield put({type: getErrorActionByAction(action), payload: message});
    }
}

function* getAcceleratorsRequest(action) {
    try {
        const response = yield call(simpleRequest, getRequestUrlByAction(action), 'GET', null, null);
        yield put({type: getSuccessActionByAction(action), payload: response.data});
    } catch (error) {
        let message = getErrorMessage(error.response.status);
        yield put({type: getErrorActionByAction(action), payload: message});
    }
}

function* getAcceleratorProgramsRequest(action) {
    try {
        const response = yield call(simpleRequest, getRequestUrlByAction(action), 'GET', {acceleratorId: action.payload.acceleratorId}, null);
        yield put({type: getSuccessActionByAction(action), payload: response.data});
    } catch (error) {
        let message = getErrorMessage(error.response.status);
        yield put({type: getErrorActionByAction(action), payload: message});
    }
}

function* postFactorModelCreateRequest(action) {
    try {
        const response = yield call(simpleRequest, getRequestUrlByAction(action), 'POST', null, action.payload);
        yield put({type: getSuccessActionByAction(action), payload: action.payload});
        yield put({type: SET_FACTOR_MODEL, payload: response.data});
        yield put( {type: GLOBAL_SUCCESS, payload: response.data.stateMessage} )
    } catch (error) {
        let message = getErrorMessage(error.response.status);
        yield put({type: getErrorActionByAction(action), payload: message});
        yield put({type: GLOBAL_ERROR, payload: message});
    }
}
function getRequestUrlByAction(action) {
    switch (action.type) {
        case CHECK_INDICATORS_IN_TEMPLATES_REQUEST: {
            return URL_POST_CHECK_INDICATORS_IN_TEMPLATES
        }
        case ACCELERATORS_REQUEST: {
            return URL_GET_ACCELERATORS
        }
        case ACCELERATOR_PROGRAMS_REQUEST: {
            return URL_GET_ACCELERATOR_PROGRAMS
        }
        case FACTOR_MODEL_CREATE_REQUEST: {
            return URL_POST_CREATE_FACTOR_MODEL
        }
        default:
            return ""
    }
}

function getSuccessActionByAction(action) {
    switch (action.type) {
        case CHECK_INDICATORS_IN_TEMPLATES_REQUEST: {
            return CHECK_INDICATORS_IN_TEMPLATES_REQUEST_SUCCESS
        }
        case ACCELERATORS_REQUEST: {
            return ACCELERATORS_REQUEST_SUCCESS
        }
        case ACCELERATOR_PROGRAMS_REQUEST: {
            return ACCELERATOR_PROGRAMS_REQUEST_SUCCESS
        }
        case FACTOR_MODEL_CREATE_REQUEST: {
            return FACTOR_MODEL_CREATE_REQUEST_SUCCESS
        }
        default:
            return ""
    }
}

function getErrorActionByAction(action) {
    switch (action.type) {
        case CHECK_INDICATORS_IN_TEMPLATES_REQUEST: {
            return CHECK_INDICATORS_IN_TEMPLATES_REQUEST_FAILED
        }
        case ACCELERATORS_REQUEST: {
            return ACCELERATORS_REQUEST_FAILED
        }
        case ACCELERATOR_PROGRAMS_REQUEST: {
            return ACCELERATOR_PROGRAMS_REQUEST_FAILED
        }
        case FACTOR_MODEL_CREATE_REQUEST: {
            return FACTOR_MODEL_CREATE_REQUEST_FAILED
        }
        default:
            return ""
    }
}
