import axios from "axios";
import {TOKEN_KEY} from "../ducks/AuthReducer";

export const URL_AUTH = "/login";
export const URL_GET_APP_NAME = "/getAppName";
export const URL_HEALTH_CHECK = "/health";
export const URL_GET_STAGE_LIST = "/api/v1/internal/stage/getList";
export const URL_GET_GROUP_LIST = "/api/v1/internal/group/getList";
export const URL_GET_FACTOR_LIST = "/api/v1/internal/factor/getList";
export const URL_GET_INDICATOR_LIST = "/api/v1/internal/indicator/getList";
export const URL_GET_LOCALIZATION = "/get-localization"

export const URL_GET_STAGE_TABLE = "/api/v1/internal/stage/getTablePage";
export const URL_GET_GROUP_TABLE = "/api/v1/internal/group/getTablePage";
export const URL_GET_FACTOR_TABLE = "/api/v1/internal/factor/getTablePage";
export const URL_GET_INDICATOR_TABLE = "/api/v1/internal/indicator/getTablePage";

export const URL_PUT_STAGE_CREATE = "/api/v1/internal/stage/add";
export const URL_PUT_GROUP_CREATE = "/api/v1/internal/group/add";
export const URL_PUT_FACTOR_CREATE = "/api/v1/internal/factor/add";
export const URL_PUT_INDICATOR_CREATE = "/api/v1/internal/indicator/add";

export const URL_DELETE_STAGE_DELETE = "/api/v1/internal/stage/delete";
export const URL_DELETE_GROUP_DELETE = "/api/v1/internal/group/delete";
export const URL_DELETE_FACTOR_DELETE = "/api/v1/internal/factor/delete";
export const URL_DELETE_INDICATOR_DELETE = "/api/v1/internal/indicator/delete";

export const URL_POST_CHECK_INDICATORS_IN_TEMPLATES = "/api/v1/internal/factorModel/checkIndicatorsInTemplates"
export const URL_GET_ACCELERATORS = "/api/v1/internal/bch/accelerator/getList"
export const URL_GET_ACCELERATOR_PROGRAMS = "/api/v1/internal/bch/acceleratorProgram/getTree"

export const URL_GET_TABLE_PAGE = "/api/v1/internal/factorModel/getTablePage";
export const URL_GET_FACTOR_MODEL = "/api/v1/internal/factorModel/getFactorModel";
export const URL_POST_CREATE_FACTOR_MODEL = "/api/v1/internal/factorModel/createFactorModel";
export const URL_POST_COPY_FACTOR_MODEL = "/api/v1/internal/factorModel/copyFactorModel";
export const URL_DELETE_DELETE_FACTOR_MODEL = "/api/v1/internal/factorModel/deleteFactorModel";
export const URL_POST_CHECK_INDICATORS_IN_TEMPLATE = "/api/v1/internal/factorModel/checkIndicatorsInTemplates";

export const simpleRequest = (url, method, params, data) => {
    let tokenHeader = getTokenHeader();
    return axios({
        url: url,
        method: method,
        params: params,
        data: data,
        headers: {
            "Content-Type": "application/json",
            Authorization: tokenHeader,
        },
    });
};

export const getTokenHeader = () => {
    let token = JSON.parse(localStorage.getItem(TOKEN_KEY.toString()));
    if (token) return token; //"Bearer token"
    return "";
};

//TODO!!! Better checks
export const isTokenValid = (token) => {
    let ret = false;
    if (token && token.length>0) ret = true
    return ret;
}

export function getErrorMessage(httpStatus) {
    let message;
    switch (httpStatus) {
        case 500:
            message = 'Internal Server Error';
            break;
        case 401:
            message = 'Unauthorized access';
            break;
        case 400:
            message = 'Bad request body';
            break;
        default:
            message = 'Something went wrong';
    }
    return message;
}