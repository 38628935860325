import {makeStyles} from "@material-ui/core/styles";
import {PRIMARY_COLOR} from "../const/UIConst";

export const useStyles = makeStyles((theme) => ({
    root: {
        height: "100vh",
    },
    primaryBackgroundColor: {
        backgroundColor: PRIMARY_COLOR,
    },
    primaryColor: {
        color: PRIMARY_COLOR,
    },
    colorWhite: {
        color: "white",
    },
    primaryBorderOutline: {
        '& label.Mui-focused': {
            color: PRIMARY_COLOR,
        },
        '& .MuiOutlinedInput-root': {
            backgroundColor:"white",
            '&.Mui-focused fieldset': {
                borderColor: PRIMARY_COLOR,
            },
        },
        textAlign: "left"
    },
    tableHeader: {
        backgroundColor: PRIMARY_COLOR,
        color: "white !important",
        fontWeight: "bold !important",
        border: "1px solid rgba(224, 224, 224, 1)"
    },
    tableBorderPrimary: {
        border: "1px solid rgba(224, 224, 224, 1)",
    },
    tableCellPrimary: {
        verticalAlign: "top",
        height: "20px",
    },
    tableCellNameItem: {
      maxWidth: "300px",
      whiteSpace: 'pre-wrap'
    },
    tableGrayCell: {
        border: "1px solid rgba(224, 224, 224, 1)",
        backgroundColor: "rgba(244, 244, 244, 1)"
    },
    backgroundCheckBox: {
        color: "black",
        "& .MuiIconButton-label": {
            color: PRIMARY_COLOR,
            position: "relative",
            zIndex: 0
        },
        "& .MuiIconButton-label:after": {
            content: '""',
            left: 4,
            top: 4,
            height: 15,
            width: 15,
            position: "absolute",
            backgroundColor: "rgba(244, 244, 244, 1)",
            zIndex: -1
        },
    },
    labelTreeView: {
        textAlign: "left",
        },
    primaryCheckBox: {
        color: "black",
        "& .MuiIconButton-label": {
            color: PRIMARY_COLOR,
            position: "relative",
            zIndex: 0
        },
    },
    checkIndicatorModal: {
        height: "200px",
        overflow : "scroll"
    }

}));
