import React from 'react';
import Grid from "@material-ui/core/Grid";
import {useDispatch, useSelector} from "react-redux";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {useStyles} from "../../jss/DashboardStyles";
import {SidebarPalette} from "./SidebarPalette";
import {
    CLOSE_SIDEBAR_LEFT,
    CLOSE_SIDEBAR_RIGHT,
    OPEN_SIDEBAR_LEFT,
    OPEN_SIDEBAR_RIGHT
} from "../../ducks/UserInterfaceReducer";
import {SidebarSettings} from "./SidebarSettings";
import {
    FACTOR_DROPPABLE_ID,
    GROUP_DROPPABLE_ID, INDICATOR_DROPPABLE_ID,
    PALETTE_SIDEBAR_WIDTH,
    SETTINGS_SIDEBAR_WIDTH,
    SIDEBAR_MINIMAL_WIDTH, STAGE_DROPPABLE_ID,
    TOP_PANEL_HEIGHT
} from "../../const/UIConst";
import Alert from "@material-ui/lab/Alert";
import {DragDropContext} from "react-beautiful-dnd";
import {Desktop} from "./desktop/Desktop";
import {FACTOR_ADD, GROUP_ADD, INDICATOR_ADD, STAGE_ADD} from "../../ducks/FactorModelReducer";

export const DashboardPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch()

    const sidebarPaletteOpened  = useSelector(store => store.user_interface.sidebarPaletteOpen)
    const sidebarSettingsOpened = useSelector(store => store.user_interface.sidebarSettingsOpen)
    const error = useSelector(store => store.user_interface.error)  //all global error must throw GLOBAL_ERROR action
    const success = useSelector(store => store.user_interface.success)  //all global error must throw GLOBAL_ERROR action

    function openSidebarLeft() {
        if (sidebarPaletteOpened === true) {
            dispatch({ type: CLOSE_SIDEBAR_LEFT});
        } else {
            dispatch({ type: OPEN_SIDEBAR_LEFT});
        }
    }

    function openSidebarRight() {
        if (sidebarSettingsOpened === true) {
            dispatch({ type: CLOSE_SIDEBAR_RIGHT });
        } else {
            dispatch({ type: OPEN_SIDEBAR_RIGHT });
        }
    }

    const OnDragEnd = (result) => {
        //console.log(result)
        if (!result.destination) return;
        if (result.destination.droppableId === result.source.droppableId) return;
        const dragItem = JSON.parse(result.draggableId)

        if (result.type === STAGE_DROPPABLE_ID){
            dispatch({type: STAGE_ADD, payload: dragItem})
        }
        if (result.type === GROUP_DROPPABLE_ID ){
            dispatch({type: GROUP_ADD, payload: dragItem})
        }
        if (result.type === FACTOR_DROPPABLE_ID){
            dispatch({type: FACTOR_ADD, payload: {
                id: dragItem.id,
                    name: dragItem.name,
                    target: JSON.parse(result.destination.droppableId)
                }})
        }
        if (result.type === INDICATOR_DROPPABLE_ID){
            dispatch({type: INDICATOR_ADD, payload: {
                    id: dragItem.id,
                    name: dragItem.name,
                    target: JSON.parse(result.destination.droppableId)
                }})
        }
    }

    return(
        <Grid container component="main" className={classes.root}>
            {error && <Alert severity='error' style={{position:"fixed",zIndex:1,left:"50%",transform:"translate(-50%,0%)"}}> {error} </Alert>}
            {success && <Alert severity='success' style={{position:"fixed",zIndex:1,left:"50%",transform:"translate(-50%,0%)"}}> {success} </Alert>}
            <DragDropContext onDragEnd={OnDragEnd}>
                <SidebarPalette/>
                <SidebarSettings/>
                <Grid container component={Paper} style={{flexWrap: 'nowrap'}}>

                    <Grid item style={{width: sidebarPaletteOpened ? PALETTE_SIDEBAR_WIDTH : SIDEBAR_MINIMAL_WIDTH}}>
                            <IconButton onClick={openSidebarLeft} style={{ position: "sticky", top: 0}}>
                            <ChevronRightIcon />
                        </IconButton>
                        </Grid>

                        {/*TODO Change table to Droppable component*/}
                        <Grid item style={{flex:"auto", width:"300px"}}>
                            <div style={{width:"100%"}}/>
                            <Desktop/>
                        </Grid>

                        <Grid item style={{width: sidebarSettingsOpened ? SETTINGS_SIDEBAR_WIDTH : SIDEBAR_MINIMAL_WIDTH}}>
                            <IconButton onClick={openSidebarRight}  style={{ position: "sticky", top: 0}}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Grid>
                </Grid>
            </DragDropContext>

        </Grid>
    )
}