import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import {CONST_LANGUAGE_EN, CONST_LANGUAGE_RU} from "./const/UIConst";

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: false,
        lng: CONST_LANGUAGE_EN,
        fallbackLng: CONST_LANGUAGE_EN,
        supportedLngs: [CONST_LANGUAGE_RU, CONST_LANGUAGE_EN],
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json",
        },
    })
    .then();

export default i18n;