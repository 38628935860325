import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Redirect} from "react-router-dom";
import {ROUTES_MAIN_MENU} from "../../const/ConstRoutes";
import {SET_ACTIVE_MENU_ITEM} from "../../ducks/UserInterfaceReducer";
import {useDispatch, useSelector} from "react-redux";
import {
  FACTOR_REQUEST_CREATE,
  FACTOR_REQUEST_DELETE,
  FACTOR_REQUEST_TABLE,
  GROUP_REQUEST_CREATE,
  GROUP_REQUEST_DELETE,
  GROUP_REQUEST_TABLE,
  INDICATOR_REQUEST_CREATE,
  INDICATOR_REQUEST_DELETE,
  INDICATOR_REQUEST_TABLE,
  MODAL_DICT_CREATE_NEW_CLOSE,
  MODAL_DICT_CREATE_NEW_SHOW,
  MODAL_DICT_DELETE_CLOSE,
  MODAL_DICT_DELETE_OPEN,
  SET_DICT_PAGE_NUMBER,
  SET_DICT_ROWS_PER_PAGE,
  STAGE_REQUEST_CREATE,
  STAGE_REQUEST_DELETE,
  STAGE_REQUEST_TABLE
} from "../../ducks/DictionaryTableReducer";
import {DICT_FACTORS, DICT_GROUPS, DICT_INDICATORS, DICT_STAGES} from "../../const/UIConst";
import {Button} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Checkbox from "@material-ui/core/Checkbox";
import TablePagination from "@material-ui/core/TablePagination";
import {SimpleModal} from "../modal/Modals";
import {useStyles} from "../../jss/DashboardStyles";
import {validateNumeric} from "../../service/ValidationService";
import {useTranslation} from "react-i18next";

const getCreateDictType = (type) => {
  switch (type) {
    case DICT_GROUPS: {
      return GROUP_REQUEST_CREATE
    }
    case DICT_FACTORS: {
      return FACTOR_REQUEST_CREATE
    }
    case DICT_INDICATORS: {
      return INDICATOR_REQUEST_CREATE
    }
    case DICT_STAGES: {
      return STAGE_REQUEST_CREATE
    }
    default:
      return ""
  }
}

const getDeleteDictType = (type) => {
  switch (type) {
    case DICT_GROUPS: {
      return GROUP_REQUEST_DELETE
    }
    case DICT_FACTORS: {
      return FACTOR_REQUEST_DELETE
    }
    case DICT_INDICATORS: {
      return INDICATOR_REQUEST_DELETE
    }
    case DICT_STAGES: {
      return STAGE_REQUEST_DELETE
    }
    default:
      return ""
  }
}

const getDateFromObject = (date) => {
  return date
}

export const DictionaryTable = ({location}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const dictType = location.dictType
  const {t} = useTranslation();

  const dictionary_list = useSelector(store => store.dictionaryTable.dictionary_list)
  const showModalCreateNew = useSelector(store => store.dictionaryTable.showModalCreateNew)
  const showModalDelete = useSelector(store => store.dictionaryTable.showModalDelete)
  const rowsPerPage = useSelector(store => store.dictionaryTable.rowsPerPage)
  const pageNumber = useSelector(store => store.dictionaryTable.pageNumber)
  const [selectedIds, setSelectedIds] = useState([])
  const [searchString, setSearchString] = useState("")
  const [newName, setNewName] = useState("")
  const [newCode, setNewCode] = useState("")
  const [newPrice, setNewPrice] = useState('')

  useEffect(() => {
    if (dictType) {
      dispatch({type: SET_ACTIVE_MENU_ITEM, payload: dictType})
      switch (dictType) {
        case DICT_GROUPS: {
          dispatch({type: GROUP_REQUEST_TABLE, payload: {page: pageNumber, size: rowsPerPage, name: searchString}})
          break
        }
        case DICT_FACTORS: {
          dispatch({type: FACTOR_REQUEST_TABLE, payload: {page: pageNumber, size: rowsPerPage, name: searchString}})
          break
        }
        case DICT_INDICATORS: {
          dispatch({type: INDICATOR_REQUEST_TABLE, payload: {page: pageNumber, size: rowsPerPage, name: searchString}})
          break
        }
        case DICT_STAGES: {
          dispatch({type: STAGE_REQUEST_TABLE, payload: {page: pageNumber, size: rowsPerPage, name: searchString}})
          break
        }
        default:
          return []
      }
    }
    dispatch({type: MODAL_DICT_CREATE_NEW_CLOSE})
    dispatch({type: MODAL_DICT_DELETE_CLOSE})
  }, [dictType, pageNumber, rowsPerPage, dispatch])

  if (!dictType) {
    return <Redirect to={ROUTES_MAIN_MENU}/>
  }

  const isInSelected = (id) => {
    return selectedIds.includes(id)
  }
  const toggleSelected = (id) => {
    if (selectedIds.includes(id))
      setSelectedIds(selectedIds.filter(x => x !== id))
    else
      setSelectedIds(selectedIds.concat([id]))
  }
  const handleChangePage = (event, newPage) => {
    dispatch({type: SET_DICT_PAGE_NUMBER, payload: newPage});
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch({type: SET_DICT_ROWS_PER_PAGE, payload: parseInt(event.target.value, 10)});
    dispatch({type: SET_DICT_PAGE_NUMBER, payload: 0});
  };

  const textSearch = () => {
    switch (dictType) {
      case DICT_GROUPS: {
        dispatch({type: GROUP_REQUEST_TABLE, payload: {page: pageNumber, size: rowsPerPage, name: searchString}})
        break
      }
      case DICT_FACTORS: {
        dispatch({type: FACTOR_REQUEST_TABLE, payload: {page: pageNumber, size: rowsPerPage, name: searchString}})
        break
      }
      case DICT_INDICATORS: {
        dispatch({type: INDICATOR_REQUEST_TABLE, payload: {page: pageNumber, size: rowsPerPage, name: searchString}})
        break
      }
      case DICT_STAGES: {
        dispatch({type: STAGE_REQUEST_TABLE, payload: {page: pageNumber, size: rowsPerPage, name: searchString}})
        break
      }
      default:
        return []
    }
  }

  const createNewValidate = () => {
    return !newName || newName.length < 1 || !newCode || newCode.length < 1 || (dictType === DICT_STAGES && (!newPrice || newPrice.toString().length < 0));
  }

  return (
    <>
      <Grid container item xs={12} justify={"flex-end"}>
        <TextField
          label={t("searchText")}
          placeholder={t("searchText")}
          variant={"outlined"}
          size={"small"}
          style={{margin: "8px",}}
          className={classes.primaryBorderOutline}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.primaryBackgroundColor}
          onClick={(e) => {
            e.preventDefault()
            textSearch()
          }}
          style={{margin: "8px 8px 8px -8px"}}
        >
          <SearchIcon/>
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.primaryBackgroundColor}
          onClick={(e) => {
            setNewName("")
            setNewCode("")
            setNewPrice('')
            dispatch({type: MODAL_DICT_CREATE_NEW_SHOW})
          }}
          style={{margin: "8px"}}
        >
          <AddBoxIcon/>
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.primaryBackgroundColor}
          onClick={(e) => dispatch({type: MODAL_DICT_DELETE_OPEN})}
          style={{margin: "8px"}}
          disabled={selectedIds.length < 1}
        >
          <DeleteIcon/>
        </Button>
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader} align="left"/>
              <TableCell className={classes.tableHeader} align="left">{t("name")}</TableCell>
              <TableCell className={classes.tableHeader} align="left">{t("code")}</TableCell>
              <TableCell className={classes.tableHeader} align="left">{t("createDate")}</TableCell>
              {dictType === DICT_STAGES &&
                <TableCell className={classes.tableHeader} align="left">{t("price")}</TableCell>
              }
            </TableRow>
          </TableHead>
          <TableBody>

            {dictionary_list.items && dictionary_list.items.map((item, index) => {
              return (
                <TableRow key={index} role="checkbox"
                          style={{backgroundColor: isInSelected(item.id) ? "rgba(244, 244, 244, 1)" : "white"}}
                          onClick={(e) => {
                            e.preventDefault()
                            toggleSelected(item.id)
                          }}
                >
                  <TableCell size={"small"} padding="checkbox">
                    <Checkbox
                      className={classes.primaryCheckBox}
                      checked={isInSelected(item.id)}
                    />
                  </TableCell>
                  <TableCell className={classes.tableCellNameItem} size={"small"}>
                    {item.name}
                  </TableCell>
                  <TableCell size={"small"}>
                    {item.code}
                  </TableCell>
                  <TableCell size={"small"}>
                    {item.createDate && getDateFromObject(item.createDate)}
                  </TableCell>
                  {dictType === DICT_STAGES &&
                    <TableCell size={"small"}>
                      {item.price}
                    </TableCell>
                  }
                </TableRow>
              )
            })}

          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={dictionary_list.totalItems ? dictionary_list.totalItems : 0}
        rowsPerPage={rowsPerPage}
        page={pageNumber}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage={t("labelRowsPerPage")}
        labelDisplayedRows={({from, to, count}) => `${from}-${to} ` + t("labelDisplayedRowsMiddle") + ` ${count}`}
      />

      <SimpleModal
        open={showModalCreateNew}
        caption={t("modalDictCreateCaption")}
        closeAction={MODAL_DICT_CREATE_NEW_CLOSE}
        okAction={(e) => {
          e.preventDefault()
          dispatch({
            type: getCreateDictType(dictType), payload: dictType === DICT_STAGES ? {id: null, name: newName, code: newCode, price: newPrice, createDate: null}
              : {id: null, name: newName, code: newCode, createDate: null}
          })
          dispatch({type: MODAL_DICT_CREATE_NEW_CLOSE})
        }}
        okCaption={t("modalCREATE")}
        okDisabled={createNewValidate()}
      >
        <Grid container item sx={12} spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              value={newName}
              label={t("name")}
              placeholder={t("name")}
              variant={"outlined"}
              size={"small"}
              fullWidth
              className={classes.primaryBorderOutline}
              onChange={(e) => setNewName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              value={newCode}
              label={t("code")}
              placeholder={t("code")}
              variant={"outlined"}
              size={"small"}
              fullWidth
              className={classes.primaryBorderOutline}
              onChange={(e) => setNewCode(e.target.value)}
            />
          </Grid>

          {dictType === DICT_STAGES &&
            <Grid item xs={12}>
              <TextField
                required
                value={newPrice}
                label={t("price")}
                placeholder={t("price")}
                variant={"outlined"}
                size={"small"}
                fullWidth
                className={classes.primaryBorderOutline}
                onChange={(e) => {
                  if (validateNumeric(e.target.value)) {
                    setNewPrice(e.target.value)
                  }
                }}
              />
            </Grid>
          }
        </Grid>
      </SimpleModal>

      <SimpleModal
        open={showModalDelete}
        caption={t("modalDeleteCaption")}
        closeAction={MODAL_DICT_DELETE_CLOSE}
        okAction={(e) => {
          e.preventDefault()
          for (let id of selectedIds) {
            dispatch({type: getDeleteDictType(dictType), payload: {id: id}})
          }
          setSelectedIds([])
          dispatch({type: MODAL_DICT_DELETE_CLOSE})
        }}
        okCaption={t("modalDELETE")}
      />
    </>
  )
}