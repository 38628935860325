import {CONST_LANGUAGE_EN, CONST_LANGUAGE_RU} from "../const/UIConst";

export const OPEN_SIDEBAR_LEFT = "OPEN_SIDEBAR_LEFT";
export const OPEN_SIDEBAR_RIGHT = "OPEN_SIDEBAR_RIGHT";
export const CLOSE_SIDEBAR_LEFT = "CLOSE_SIDEBAR_LEFT";
export const CLOSE_SIDEBAR_RIGHT = "CLOSE_SIDEBAR_RIGHT";
export const GLOBAL_ERROR = "GLOBAL_ERROR"
export const GLOBAL_ERROR_CLEAR = "GLOBAL_ERROR_CLEAR"
export const OPEN_MAIN_SIDEBAR = "OPEN_MAIN_SIDEBAR";
export const CLOSE_MAIN_SIDEBAR= "CLOSE_MAIN_SIDEBAR";
export const SET_ACTIVE_MENU_ITEM = "SET_ACTIVE_MENU_ITEM";
export const GLOBAL_SUCCESS = "GLOBAL_SUCCESS"
export const GLOBAL_SUCCESS_CLEAR = "GLOBAL_SUCCESS_CLEAR"
export const CHANGE_LANGUAGE_RU = "CHANGE_LANGUAGE_RU"
export const CHANGE_LANGUAGE_EN = "CHANGE_LANGUAGE_EN"

export const GET_LOCALIZATION ="GET_LOCALIZATION"
export const SET_LOCALIZATION ="SET_LOCALIZATION"

const initialState = {
    mainSidebarOpen: true,
    sidebarPaletteOpen: true,
    sidebarSettingsOpen: true,
    activeMenuItem: "",
    error: null,
    success: null,
    language:  CONST_LANGUAGE_EN,
    availableLocalization: []
};

export const UserInterfaceReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case OPEN_SIDEBAR_LEFT: {
            return { ...state, sidebarPaletteOpen: true };
        }
        case OPEN_SIDEBAR_RIGHT: {
            return { ...state, sidebarSettingsOpen: true };
        }
        case CLOSE_SIDEBAR_LEFT: {
            return { ...state, sidebarPaletteOpen: false };
        }
        case CLOSE_SIDEBAR_RIGHT: {
            return {...state, sidebarSettingsOpen: false};
        }
        case OPEN_MAIN_SIDEBAR: {
            return { ...state, mainSidebarOpen: true };
        }
        case CLOSE_MAIN_SIDEBAR: {
            return { ...state, mainSidebarOpen: false };
        }
        case GLOBAL_ERROR: {
            return {...state, error: payload};
        }
        case GLOBAL_ERROR_CLEAR: {
            return {...state, error: null};
        }
        case GLOBAL_SUCCESS: {
            return {...state, success: payload};
        }
        case GLOBAL_SUCCESS_CLEAR: {
            return {...state, success: null};
        }
        case SET_ACTIVE_MENU_ITEM: {
            return {...state, activeMenuItem: payload};
        }
        case CHANGE_LANGUAGE_RU: {
            return {...state, language: CONST_LANGUAGE_RU};
        }
        case CHANGE_LANGUAGE_EN: {
            return {...state, language: CONST_LANGUAGE_EN};
        }
        case SET_LOCALIZATION: {
          return { ...state, availableLocalization: payload.availableLocalization, language: payload.defaultLocalization  };
      }
        default:
            return state;
    }
};
