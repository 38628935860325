import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {CLOSE_SIDEBAR_RIGHT, GLOBAL_SUCCESS, OPEN_SIDEBAR_RIGHT,} from "../../ducks/UserInterfaceReducer";
import {useStyles} from "../../jss/DashboardStyles";
import {Button, Grid, Typography} from "@material-ui/core";
import {AUTH_LOGOUT} from "../../ducks/AuthReducer";
import Divider from "@material-ui/core/Divider";
import {SETTINGS_SIDEBAR_WIDTH, TOP_PANEL_HEIGHT} from "../../const/UIConst";
import {
    ACCELERATOR_PROGRAMS_REQUEST,
    ACCELERATORS_REQUEST,
    CHECK_INDICATORS_IN_TEMPLATES_REQUEST,
    CHECK_MODEL,
    FACTOR_MODEL_CREATE_REQUEST,
    MODAL_CHECK_INDICATORS_CLOSE,
    MODAL_CHECK_INDICATORS_OPEN,
    MODAL_CHECK_MODEL_CLOSE,
    MODAL_CHECK_MODEL_OPEN
} from "../../ducks/SidebarSettingsReducer";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import {ExpandMoreOutlined} from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
    SET_FACTOR_MODEL_ACCELERATOR_ID,
    SET_FACTOR_MODEL_CODE,
    SET_FACTOR_MODEL_DESCRIPTION,
    SET_FACTOR_MODEL_NAME,
    SET_FACTOR_MODEL_STATUS,
    SET_FACTOR_MODEL_TRACK_POINTS
} from "../../ducks/FactorModelReducer";
import {SimpleModal} from "../modal/Modals";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {ROUTES_MAIN_MENU} from "../../const/ConstRoutes";
import {STATUS_OPTIONS} from "../../const/EnumConst";

export const SidebarSettings = () => {
    const classes = useStyles();
    const dispatch = useDispatch()
    const {t} = useTranslation();

    const sidebarOpened = useSelector(store => store.user_interface.sidebarSettingsOpen)
    const factorModel = useSelector(store => store.desktop.factorModel)
    const sideBarSettingsStore = useSelector(state => state.sideBarSettings)

    const [selectedAccelerator, setSelectedAccelerator] = useState({});
    const [selectedAcceleratorProgramTree, setSelectedAcceleratorProgramTree] = useState([])
    const [selectedChildAcceleratorProgramTree, setSelectedChildAcceleratorProgramTree] = useState([])
    const [hasResponse, setHasResponse] = useState(true)

    useEffect(() => {
        if (sideBarSettingsStore.problematicIndicators.length > 0) {
            dispatch({type: MODAL_CHECK_INDICATORS_OPEN, payload: true})
        }
    }, [sideBarSettingsStore.problematicIndicators, dispatch])

    useEffect(() => {
        dispatch({type: ACCELERATORS_REQUEST})
    }, [dispatch])

    useEffect(() => {
        if (sideBarSettingsStore.acceleratorList.length > 0) {
            setSelectedAccelerator(sideBarSettingsStore.acceleratorList.find(x => x.id === factorModel.acceleratorId))
        }
        dispatch({
            type: ACCELERATOR_PROGRAMS_REQUEST,
            payload: {acceleratorId: factorModel.acceleratorId !== null ? factorModel.acceleratorId : 0}
        })
    }, [factorModel.acceleratorId])

    useEffect(() => {
        setSelectedAcceleratorProgramTree(factorModel.trackPoints)
    }, [factorModel.acceleratorId])
    useEffect(() => {
        setHasResponse(true)
    }, [factorModel])

    function getIndicatorArray(groups) {
        let array = [];
        for (let group of groups) {
            for (let factor of group.factors) {
                for (let indicator of factor.indicators) {
                    array.push(indicator.indicatorId)
                }
            }
        }
        return array
    }

    function setFactorModelNameHandler(value) {
        dispatch({type: SET_FACTOR_MODEL_NAME, payload: value})
    }
    function isSaveButtonDisabled() {
        return (factorModel.name.length === 0 || factorModel.code.length === 0) || !hasResponse
    }

    function setFactorModelCodeHandler(value) {
        dispatch({type: SET_FACTOR_MODEL_CODE, payload: value})
    }

    function setFactorModelDescriptionHandler(value) {
        dispatch({type: SET_FACTOR_MODEL_DESCRIPTION, payload: value})
    }

    function selectFactorModelAcceleratorIdHandle(value) {
        dispatch({type: SET_FACTOR_MODEL_ACCELERATOR_ID, payload: value !== null ? value.id : 0})
        setSelectedAcceleratorProgramTree([]);
        setSelectedChildAcceleratorProgramTree([])
        selectFactorModelTrackPoints([])
    }

    function selectFactorModelTrackPoints(value) {
        dispatch({type: SET_FACTOR_MODEL_TRACK_POINTS, payload: value})
    }


    function toggleSidebar() {
        if (sidebarOpened === true) {
            dispatch({type: CLOSE_SIDEBAR_RIGHT});
        } else {
            dispatch({type: OPEN_SIDEBAR_RIGHT});
        }
    }

    const logout = () => dispatch({type: AUTH_LOGOUT})

    const checkIndicatorsInTemplates = () => {
        dispatch({
            type: CHECK_INDICATORS_IN_TEMPLATES_REQUEST,
            payload: {indicators: getIndicatorArray(factorModel.groups), trackPoints: factorModel.trackPoints}
        })
    }

    const checkFactorModel = () => {
        let errorMsg = []
        for (let stage of factorModel.stages) {
            // Проверка групп
            let sumGroupWeightByStage = 0
            for (let group of factorModel.groups) {
                let groupStageWeights = group.stageWeights.find(groupStageWeights => groupStageWeights.stageId === stage.id)
                sumGroupWeightByStage += parseFloat(groupStageWeights.weight)
                //console.log(stage.name + ' ' + group.name + ': ' + groupStageWeights.weight)
                // Проверка факторов
                let sumFactorWeightByStage = 0
                for (let factor of group.factors) {
                    let factorStageWeights = factor.stageWeights.find(factorStageWeights => factorStageWeights.stageId === stage.id)
                    sumFactorWeightByStage += parseFloat(factorStageWeights.weight)
                    //console.log(stage.name + ' ' + group.name + ' ' + factor.name + ': ' + factorStageWeights.weight)
                    // Проверка индикаторов
                    let sumIndicatorWeightByStage = 0
                    for (let indicator of factor.indicators) {
                        let indicatorStageWeights = indicator.stageWeights.find(indicatorStageWeights => indicatorStageWeights.stageId === stage.id)
                        sumIndicatorWeightByStage += parseInt((parseFloat(indicatorStageWeights.weight) * 10).toFixed())
                        //console.log(stage.name + ' ' + group.name + ' ' + factor.name + ' ' + indicator.name + ': ' + indicatorStageWeights.weight)
                    }
                    if (sumIndicatorWeightByStage === 10) {
                        console.log(stage.name + ' sum indicator ok')
                    } else {
                        //errorMsg = stage.name + ' sum indicator bad'
                        // let newLength = errorMsg.push(`Стадия "${stage.name}": сумма весов индикаторов у фактора "${factor.name}" не равна "1".`)
                        let newLength = errorMsg.push(t('errorMessageStageNotOne', { stageName: stage.name, factorName: factor.name }))
                        console.log(errorMsg[newLength - 1])
                    }
                }
                if (sumFactorWeightByStage === parseFloat(groupStageWeights.weight)) {
                    console.log(stage.name + ' ' + group.name + ' sum factor ok')
                } else {
                    // let newLength = errorMsg.push(`Стадия "${stage.name}": сумма % факторов должна равняться % группы факторов "${group.name}".`)
                    let newLength = errorMsg.push(t('errorMessageNotEqualGroup', { stageName: stage.name, groupName: group.name }))
                    console.log(errorMsg[newLength - 1])
                }
            }
            if (sumGroupWeightByStage === 100) {
                console.log(stage.name + ' sum group ok')
            } else {
                // let newLength = errorMsg.push(`Стадия "${stage.name}": сумма % групп факторов должна равняться 100%.`)
                let newLength = errorMsg.push(t('errorMessageStageNotHundredPercent', { stageName: stage.name }))
                console.log(errorMsg[newLength - 1])
            }
        }
        if (errorMsg.length !== 0) {
            dispatch({type: CHECK_MODEL, payload: errorMsg})
            dispatch({type: MODAL_CHECK_MODEL_OPEN, payload: true})
        } else {
            dispatch({type: CHECK_MODEL, payload: []})
            dispatch({type: GLOBAL_SUCCESS, payload: "Model checked!"})
        }
    }

    const saveFactorModel = () => {
        setHasResponse(false);
        dispatch({
            type: FACTOR_MODEL_CREATE_REQUEST,
            payload: factorModel
        })
    }

    function unique(arr) {
        let result = [];
        for (let str of arr) {
            if (!result.includes(str)) {
                result.push(str);
            }
        }
        return result;
    }

    function getChildById(node, id) {
        let array = [];

        function getAllChild(nodes) {
            if (nodes === null) return [];
            array.push(nodes.id);
            if (Array.isArray(nodes.children)) {
                nodes.children.forEach(node => {
                    array = [...array, ...getAllChild(node)];
                    array = array.filter((v, i) => array.indexOf(v) === i);
                });
            }
            return array;
        }

        function getNodeById(nodes, id) {
            if (nodes.id === id) {
                return nodes;
            } else if (Array.isArray(nodes.children)) {
                let result = null;
                nodes.children.forEach(node => {
                    if (!!getNodeById(node, id)) {
                        result = getNodeById(node, id);
                    }
                });
                return result;
            }
            return null;
        }

        return getAllChild(getNodeById(node, id));
    }

    function getOnChange(checked, nodes) {
        const allNode = getChildById(sideBarSettingsStore.acceleratorProgramTree, nodes.id);
        let array = checked
            ? [...selectedAcceleratorProgramTree, ...allNode]
            : selectedAcceleratorProgramTree.filter(value => !allNode.includes(value));
        array = array.filter((v, i) => array.indexOf(v) === i);
        setSelectedAcceleratorProgramTree(array);

        const leaves = getLeaves(nodes)
        if (checked) {
            const concatenated = selectedChildAcceleratorProgramTree.concat(leaves)
            setSelectedChildAcceleratorProgramTree(concatenated)
            selectFactorModelTrackPoints(unique(concatenated))
        } else {
            const unchecked = unCheckLeaves(selectedChildAcceleratorProgramTree, leaves)
            setSelectedChildAcceleratorProgramTree(unchecked)
            selectFactorModelTrackPoints(unique(unchecked))
        }
    }

    function unCheckLeaves(allLeaves, unCheckedNodes) {
        if (!unCheckedNodes || unCheckedNodes.length === 0) {
            return allLeaves
        }
        const result = []
        allLeaves.forEach(node => {
            const idx = unCheckedNodes.findIndex(i => i === node)
            if (idx < 0) {
                result.push(node)
            }
        })
        return result;
    }

    function getLeaves(nodes) {
        if (!nodes.children) {
            return [nodes.id]
        } else {
            return nodes.children.reduce((acc, curr) => {
                acc.push(...getLeaves(curr))
                return acc
            }, [])
        }
    }

    const renderCheckIndicatorModal = () => (
        <SimpleModal
            open={sideBarSettingsStore.showModalCheckIndicators}
            caption={t("modalCheckIndicatorsTemplateCaption")}
            closeAction={MODAL_CHECK_INDICATORS_CLOSE}
            okAction={() => {
                dispatch({type: MODAL_CHECK_INDICATORS_CLOSE})
            }}
            okCaption={"ОК"}
        >
            <FormControlLabel
                className={classes.labelTreeView}
                label={''}
                control={
                    <div>
                        <div>
                          {t('selectedActivitiesLack')}
                        </div>
                        <br/>

                        <div className={classes.checkIndicatorModal}>
                        <ul>
                            {sideBarSettingsStore.problematicIndicators.map(function (d, idx) {
                                return (<li key={idx}>{d}</li>)
                            })}
                        </ul>
                        </div>
                    </div>
                }
            />
        </SimpleModal>
    )

    const renderCheckModelModal = () => (
        <SimpleModal
            open={sideBarSettingsStore.showModalCheckModel}
            caption={t("modalCheckFMCaption")}
            closeAction={MODAL_CHECK_MODEL_CLOSE}
            okAction={() => {
                dispatch({type: MODAL_CHECK_MODEL_CLOSE})
            }}
            okCaption={"ОК"}
        >
            <FormControlLabel
                className={classes.labelTreeView}
                label={''}
                control={
                    <div>
                        <div>
                          {t('calculationIsNotPossible')}
                        </div>
                        <br/>

                        <div className={classes.checkIndicatorModal}>
                            <ul>
                                {sideBarSettingsStore.problematicModel.map(function (d, idx) {
                                    return (<li key={idx}>{d}</li>)
                                })}
                            </ul>
                        </div>
                    </div>
                }
            />
        </SimpleModal>
    )

    const renderTree = (nodes) => (
        <TreeItem
            className={classes.primaryBorderOutline}
            key={nodes.id}
            nodeId={String(nodes.id)}
            label={
                <FormControlLabel
                    className={classes.labelTreeView}
                    control={
                        <Checkbox
                            className={classes.primaryCheckBox}
                            checked={selectedAcceleratorProgramTree != null && selectedAcceleratorProgramTree.some(item => item === nodes.id)}
                            onChange={event =>
                                getOnChange(event.currentTarget.checked, nodes)
                            }
                            onClick={e => e.stopPropagation()}
                        />
                    }
                    label={<>{nodes.name}</>}
                    key={nodes.id}
                />
            }
        >
            {Array.isArray(nodes.children)
                ? nodes.children.map(node => renderTree(node))
                : []}
        </TreeItem>
    );

    return (
        <React.Fragment>
            <Drawer variant="persistent"
                    open={sidebarOpened}
                    anchor={"right"}>
                <Grid container
                      style={{width: SETTINGS_SIDEBAR_WIDTH, position: "relative", minHeight: TOP_PANEL_HEIGHT}}
                      alignItems={"center"} justify={"center"}>
                    <div style={{width: SETTINGS_SIDEBAR_WIDTH, position: "absolute"}}>
                        <IconButton onClick={toggleSidebar} style={{float: "left"}}>
                            <ChevronRightIcon/>
                        </IconButton>
                    </div>
                    <Grid item>
                        <Typography>
                            {t("settings")}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider/>

                <TextField
                    required
                    label={t("code")}
                    placeholder={t("code")}
                    value={factorModel.code}
                    variant={"outlined"}
                    size={"small"}
                    style={{margin: "8px"}}
                    className={classes.primaryBorderOutline}
                    onChange={event => {
                        const {value} = event.target;
                        setFactorModelCodeHandler(value)
                    }}
                />

                <TextField
                    required
                    label={t("name")}
                    placeholder={t("name")}
                    value={factorModel.name}
                    variant={"outlined"}
                    size={"small"}
                    style={{margin: "8px"}}
                    className={classes.primaryBorderOutline}
                    onChange={event => {
                        const {value} = event.target;
                        setFactorModelNameHandler(value)
                    }}
                />

                <TextField
                    label={t("description")}
                    placeholder={t("description")}
                    value={factorModel.description}
                    variant={"outlined"}
                    multiline
                    rows="4"
                    style={{margin: "8px"}}
                    className={classes.primaryBorderOutline}
                    onChange={event => {
                        const {value} = event.target;
                        setFactorModelDescriptionHandler(value)
                    }}
                />

                <Autocomplete
                    id="change-status-combobox"
                    className={classes.primaryBorderOutline}
                    style={{margin: "8px"}}
                    options={STATUS_OPTIONS}
                    value={factorModel.status}
                    getOptionLabel={(option) => t(option)}
                    disableClearable
                    onChange={(event, newValue) => dispatch({type:SET_FACTOR_MODEL_STATUS,payload:newValue})}
                    renderInput={(params) =>
                        <TextField {...params}
                                   label={t("status")}
                                   variant="outlined"
                                   fullWidth/>}
                />

                {sideBarSettingsStore.acceleratorList && sideBarSettingsStore.acceleratorList.length > 0 && <Autocomplete
                    id="accelerator-combobox"
                    className={classes.primaryBorderOutline}
                    style={{margin: "8px"}}

                    options={sideBarSettingsStore.acceleratorList}
                    value={selectedAccelerator}
                    getOptionLabel={(option) => option.name}

                    onChange={(event, newValue) => selectFactorModelAcceleratorIdHandle(newValue)}
                    renderInput={(params) =>
                        <TextField {...params}
                                   label={t("accelerators")}
                                   variant="outlined"
                                   fullWidth/>}
                />}

                <TreeView className={classes.primaryBorderOutline}
                          defaultCollapseIcon={<ExpandMoreOutlined/>}
                          defaultExpandIcon={<ChevronRightIcon/>}
                          multiSelect
                >
                    {Object.keys(sideBarSettingsStore.acceleratorProgramTree).length !== 0 && renderTree(sideBarSettingsStore.acceleratorProgramTree)}
                </TreeView>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.primaryBackgroundColor}
                    onClick={checkFactorModel}
                    style={{margin: "8px"}}>
                    {t("checkModelCaps")}
                </Button>

                {renderCheckModelModal()}

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.primaryBackgroundColor}
                    onClick={checkIndicatorsInTemplates}
                    style={{margin: "8px"}}>
                    {t("checkIndicatorsCaps")}
                </Button>

                {renderCheckIndicatorModal()}

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.primaryBackgroundColor}
                    onClick={saveFactorModel}
                    style={{margin: "8px"}}
                    disabled={isSaveButtonDisabled()}
                >
                    {t("saveCaps")}
                </Button>
                <Divider/>
                {/*{error && <Alert severity='error' > {error} </Alert>}*/}

                <NavLink to={ROUTES_MAIN_MENU}
                         style={{margin: "8px", textDecoration: "none"}}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        fullWidth
                        className={classes.primaryBackgroundColor}
                    >
                        {t("mainMenuCaps")}
                    </Button>
                </NavLink>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.primaryBackgroundColor}
                    onClick={logout}
                    style={{margin: "8px"}}>
                    {t("logoutCaps")}
                </Button>
            </Drawer>

        </React.Fragment>
    )
}
