import React from "react";
import { Route, Redirect } from "react-router-dom";
import {connect} from "react-redux";
import {ROUTES_LOGIN} from "../const/ConstRoutes";
import {isTokenValid} from "../service/RequestService";

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route{...rest}
              render={props =>
                  isTokenValid(rest.token) ? (
                      <Component {...props} />
                  ) : (
                      <Redirect to={{ pathname: ROUTES_LOGIN }}/>
                  )
              }
        />
    );
}

const mapStoreToProps = store => {
    return {
        token: store.login.token
    }
}

export default connect(mapStoreToProps)(PrivateRoute)