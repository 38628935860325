import {CONST_LANGUAGE_EN, CONST_LANGUAGE_RU} from "../const/UIConst";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React from "react";
import {CHANGE_LANGUAGE_EN, CHANGE_LANGUAGE_RU, GET_LOCALIZATION} from "../ducks/UserInterfaceReducer";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useEffect} from 'react';

export const LanguagePicker = () => {
    const dispatch = useDispatch()
    const language = useSelector(store => store.user_interface.language)
    const availableLocalization = useSelector(store => store.user_interface.availableLocalization)
    const { i18n } = useTranslation();

    useEffect(()=>{
      dispatch({type:GET_LOCALIZATION})
    }, [])

    useEffect(() => {
      i18n.changeLanguage(language);
    }, [availableLocalization])

    function changeLanguage(e) {
        e.preventDefault();
        if (e.target.value === CONST_LANGUAGE_RU) {
            i18n.changeLanguage(CONST_LANGUAGE_RU).then();
            dispatch({ type: CHANGE_LANGUAGE_RU });
        } else {
            i18n.changeLanguage(CONST_LANGUAGE_EN).then();
            dispatch({ type: CHANGE_LANGUAGE_EN });
        }
    }

    return (
        <Select
            value={language ? language : CONST_LANGUAGE_EN}
            onChange={changeLanguage}
        >
            { 
                availableLocalization && availableLocalization.length > 0
                ? [...availableLocalization].map((x) => <MenuItem value={x}  key={x}>{x}</MenuItem>)
                : <MenuItem value={language ? language : CONST_LANGUAGE_EN}>{language ? language : CONST_LANGUAGE_EN}</MenuItem>
            }
        </Select>
    )
}