export const GET_APP_NAME = "GET_APP_NAME";
export const SET_APP_NAME = "SET_APP_NAME";
export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_CHECK = "AUTH_CHECK";
export const TOKEN_KEY = "TOKEN_KEY";


export const logout = () => ({
    type: AUTH_LOGOUT,
});

const initialState = {
    token: JSON.parse(localStorage.getItem(TOKEN_KEY.toString())),
    error: null,
    appName: null,
};

export const AuthReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case AUTH_SUCCESS: {
            return { ...state, token: payload, error: null };
        }
        case AUTH_FAILURE: {
            return { ...state, error: payload, token: null };
        }
        case AUTH_LOGOUT: {
            return { ...state, token: null };
        }
        case SET_APP_NAME: {
            return { ...state, appName: payload };
        }
        default:
            return state;
    }
};
